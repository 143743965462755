import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.png";
import LOGO_LIGHT from "./../../assets/images/logo_light.png";
import { Translate } from "react-auto-translate";
import ENGLISH from "../../assets/english.png";
import RUSSIAN from "../../assets/russia.png";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckDouble, FaBell } from "react-icons/fa";
import { MdHelpOutline } from "react-icons/md";
import { truncateText } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import {
  MdOutlineLightMode,
  MdOutlineDarkMode,
  MdOutlineNotifications,
} from "react-icons/md";
import { setUnreadNotifications } from "../../lib/Redux/slices/webData";
import { setNotificationsTostify } from "../../lib/Redux/slices/userslice";
import dummyUserImage from "../../assets/images/dummy_user.png"


function useOutsideAlerter(ref, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}

function NavBar() {
  const dispatch = useDispatch();
  const { getLoggedObject, setLoggedObject } = useAuth();
  const { Favroute = [], notifications = [] } = useSelector(
    (state) => {
      console.log(state,'state')
      return state.web}
  );
  console.log(Favroute, "askjhdsjkahdkjash")
  const { unread_notifications = [] } = notifications;

  const location = useLocation();
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const [dropdowno, setDropdowno] = useState(false);
  const [dlang, setDLang] = useState("ru");
  const [droplang, setDropLang] = useState(false);
  const [langdrop, setLangDrop] = useState(false);
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const firstSlashValue = path.substring(0, lastSlashIndex + 1);
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
  const [sidebar, setSideBar] = useState(false);
  const [usage, setUsage] = useState(getLoggedObject()?.usage);
  const [propic, setProPic] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const sidebarRef = useRef(null);
  const [Searchtext, setSearchText] = useState("");
  const [FilterData, setFilterData] = useState([]);
  const [dropdownTemplete, setDropdownTemplete] = useState(false);
  const [totaltemp, setTotalTemp] = useState(0);
  const [CurrentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng")
  );
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const handleClick = (event) => {
    if (event.target.tagName === "A") {
      setSideBar(false);
    }
  };

  const { Tokenvalue = 0 } = useSelector((state) => state.user);

  useEffect(() => {
    // set
    setProPic(getLoggedObject().profile_pic);
    document.addEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    const collapseValue = localStorage.getItem("i18nextLng");
    if (collapseValue === null) {
      setDLang("ru");
    } else {
      setDLang(collapseValue);
    }
  }, [dlang]);

  const do_update_language = (val) => {
    localStorage.setItem("i18nextLng", val);
    setDLang(val);
    window.location.reload();
  };

  const do_logout = async () => {
    localStorage.removeItem("AIWORDAUTH");
    setLoggedObject(null);
    window.location.href = "/";
  };

  useEffect(() => {
    handle_content();
  }, [currentLanguage]);
  const handle_content = async () => {
    // const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      slug: 1,
      lang: currentLanguage,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_popular_categories"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setTotal(data?.total);
        setTotalTemp(data?.total_temp);
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useOutsideAlerter(sidebarRef, () => setSideBar(false));

  const handleRoute = (v) => {
    navigate("/" + v.slug, { state: { v } });
    setSideBar(!sidebar);
  };

  const [watchvideo, setWatchVideo] = useState(false);
  const innerBoxRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (innerBoxRef.current && !innerBoxRef.current.contains(event.target)) {
        setWatchVideo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!watchvideo && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [watchvideo]);

  const handleUpdateLanguage = () => {
    const collapseValue = localStorage.getItem("i18nextLng");
    if (collapseValue === null) {
      setDLang("ru");
      localStorage.setItem("i18nextLng", "ru");
    } else if (collapseValue == "ru") {
      setDLang("en");
      do_update_language("en");
    } else if (collapseValue == "en") {
      setDLang("ru");
      do_update_language("ru");
    }
  };

  const handeFilter = async () => {
    const payload = {
      token: getLoggedObject().token,
      title: Searchtext,
      lang_id: dlang == "ru" ? 2 : 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/filterCategories");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setFilterData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    if (Searchtext !== "") {
      setFilterData("");
      handeFilter();
    } else {
      setFilterData("");
    }

    // const collapseValue = localStorage.getItem("i18nextLng");
  }, [Searchtext]);

  const [DarkMode, SetDarkMode] = useState(false);

  const handleDarkMode = () => {
    localStorage.setItem("Mode", "Dark");
    document.body.classList.add("AiwordDarkMode");
    SetDarkMode(true);
  };

  const handleLightMode = () => {
    localStorage.removeItem("Mode");
    document.body.classList.remove("AiwordDarkMode");
    SetDarkMode(false);
  };

  useEffect(() => {
    let Mode = localStorage.getItem("Mode");

    if (Mode === "Dark") {
      SetDarkMode(true);
      document.body.classList.add("AiwordDarkMode");
    } else {
      SetDarkMode(false);
      document.body.classList.remove("AiwordDarkMode");
    }
  }, []);

  const [NotficationCount, setNotficationCount] = useState(0);

  const GetNotifications = async () => {
    const payload = {
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/unReadNotificationsCount"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setLoading(false);
        dispatch(setUnreadNotifications(data));
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const MarkedNotifications = async () => {
    if (notifications?.data === 0) {
      return toast.error(
        NotificationsToasts?.no_notif_marked_as_read?.notification
      );
    }
    const payload = {
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/readAllNotifications"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setLoading(false);
        GetNotifications();
        toast.success(
          NotificationsToasts?.notification_marked_read?.notification
        );
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  // Notification PopUp

  const [NotificationPop, setNotificationPop] = useState(false);
  const [NotificationDetails, setNotificationDetails] = useState({});

  const handleSingleNotification = (data) => {
    setNotificationPop(true);
    setNotificationDetails(data?.message);
    MarkedSingleNotification(data?.id);
  };

  const MarkedSingleNotification = async (id) => {
    const payload = {
      token: getLoggedObject().token,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/readSingleNotification"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setLoading(false);
        GetNotifications();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const GetToastNotifications = async () => {
    const payload = {
      slug: 1,
      lang: dlang,
    };
    const { isError, data } = await doPost(payload, "auth/sessionNotifiations");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
    } else {
      if (data.status) {
        dispatch(setNotificationsTostify(data?.data));
      } else {
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    GetToastNotifications();
  }, [dlang]);

  const { NotificationsToasts = null } = useSelector((state) => state.user);
  // const [dropLang, setDropLang] = useState(false);  // Added for dropdown control

  const handleChangeLanguage = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    setCurrentLanguage(lang);
    changeLanguage(lang);
    setDropLang(false);  // Hide dropdown after selection
  };
  return (
    <>
      <div className={`${watchvideo ? "VideoPopActive " : "VidoPop"}  `}>
        <div ref={innerBoxRef} className="InnerBoxPop">
          {notifications?.media_url && watchvideo && (
            <video className="TitorialVideo" ref={videoRef} controls autoPlay>
              <source src={notifications?.media_url} type="video/mp4" />
              {t("common.browserDoesNotSupportTheVideoTag")}
            </video>
          )}
        </div>
      </div>
      {NotificationPop && (
        <div className="NotificationsPopUp">
          <div className="NotifcationBoxPop">
            <div className="NoteHeaderPop">
              <span>
                {t("common.notification")}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setNotificationPop(false)}
              >
                {t("common.close")}
              </span>
            </div>
            <div className="NotDetail">
              <span dangerouslySetInnerHTML={{ __html: NotificationDetails }} />
            </div>
          </div>
        </div>
      )}

      <nav className="header-nav navbar navbar-expand-lg navbar-light">
        {" "}
        <span className="navbar-brand">
          <Link className="logo" href="/dashboard">
            <img src={DarkMode ? LOGO_LIGHT : LOGO} />
          </Link>
        </span>
        <button
          aria-controls="basic-navbar-nav"
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler collapsed"
          onClick={() => setSideBar(!sidebar)}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          ref={sidebarRef}
          className={`justify-content-between navbar-collapse collapse ${sidebar ? "show" : ""
            }`}
          id="basic-navbar-nav"
        >
          <div className="MainSearch not_mobile">
            <input
              onChange={(e) => setSearchText(e.target.value)}
              value={Searchtext}
              placeholder={
                currentLanguage == "ru" ? "Искать инструменты..." : "Search for tools..."
              }
            />
            {Searchtext !== "" && (
              <div className="SearchArea">
                {FilterData.length > 0
                  ? FilterData.map((item) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchText("");
                          setSideBar(!sidebar);
                          navigate("/" + item.slug, { state: { v: item } });
                        }}
                        className="SearchList"
                        key={item?.id}
                      >
                        <img src={item?.image} style={{ width: "22px" }} />
                        <span>{item?.title}</span>
                      </div>
                    );
                  })
                  : "No tool found"}
              </div>
            )}
          </div>
          <div className="myprofile-link navbar-nav ">
            <div className="NewHeadersIcons">
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={handleIconClick}
              >
                <MdOutlineNotifications size={"20px"} />
                <div
                  className={notifications?.data > 0 ? "notif_Class_colro" : ""}
                ></div>
                {showDropdown && (
                  <div
                    className="NotifDropdropDown"
                    onClick={handleDropdownClick}
                    ref={dropdownRef}
                  >
                    <div className="NoteHead">
                      <h6 className="NoteMainH">
                        {t("common.notifications")}{" "}
                      </h6>
                      <div className="MainHeadIcons">
                        <p onClick={() => navigate("/notification")}>
                          {t("common.viewAll")}
                        </p>
                        <FaCheckDouble onClick={MarkedNotifications} />
                      </div>
                    </div>
                    {unread_notifications.length > 0 ? (
                      unread_notifications.map((item) => {
                        return (
                          <div
                            onClick={() => handleSingleNotification(item)}
                            key={item?.id}
                            className="HeadNotificationMain"
                          >
                            <div className="MainNotifiyNote">
                              <div className="NotificationGlobe"></div>
                              <div className="BellBox">
                                <FaBell className="BellNotification" />
                              </div>
                              <div>
                                <h6
                                  className="msgNote"
                                  dangerouslySetInnerHTML={{
                                    __html: truncateText(item?.message, 30),
                                  }}
                                ></h6>

                                <p className="NoteTime">
                                  <Translate>{item?.created_at}</Translate>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ padding: "10px 10px" }}>
                        {t("common.noNotificationFound")}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {DarkMode ? (
                  <MdOutlineDarkMode onClick={handleLightMode} size={"20px"} />
                ) : (
                  <MdOutlineLightMode onClick={handleDarkMode} size={"20px"} />
                )}
              </div>
              <div
                className="not_mobile"
                // onClick={handleUpdateLanguage}
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <div
                  className="language_switcher"
                  style={{ marginRight: "0px" }}
                  onClick={() => setDropLang(!droplang)}
                >
                  <img src={currentLanguage === "en" ? ENGLISH : RUSSIAN} alt="Language Icon" />
                  {droplang && (
                    <div className="switcher_dropdown">
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("en")}
                      >
                        <img src={ENGLISH} alt="English Icon" />
                        <span>{t("common.english")}</span>
                      </div>
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("ru")}
                      >
                        <img src={RUSSIAN} alt="Russian Icon" />
                        <span>{t("common.russian")}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                {/* <RiFlashlightLine size={"20px"} />
                <span
                  style={{
                    fontSize: "10px",
                  }}
                >
                  {Tokenvalue}
                </span> */}
                <div className="wordsUsed">
                  <span>
                    {" "}
                    <i className="wIcon">
                      {t("common.token")}
                    </i>
                    <p>{Tokenvalue}</p>
                  </span>
                </div>
              </div>
            </div>

            <div className="MainSearch mob_switcher">
              <input
                onChange={(e) => setSearchText(e.target.value)}
                value={Searchtext}
                placeholder={
                  dlang == "ru"
                    ? "Искать инструменты..."
                    : "Search for tools..."
                }
              />
              {Searchtext !== "" && (
                <div
                  className="SearchArea"
                  style={{ background: "#fff", zIndex: "111" }}
                >
                  {FilterData.length > 0
                    ? FilterData.map((item) => {
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSearchText("");
                            setSideBar(!sidebar);
                            navigate("/" + item.slug, { state: { v: item } });
                          }}
                          className="SearchList"
                          key={item?.id}
                        >
                          <img src={item?.image} style={{ width: "22px" }} />
                          <span>{item?.title}</span>
                        </div>
                      );
                    })
                    : "No tool found"}
                </div>
              )}
            </div>

            <div className="sideBarNav">
              <div className="side-bar-nav column">
                <div className="sidebar-content">
                  <div className="sidebar-nav">
                    <nav className="sidebar-nav">
                      <ul>
                        <li>
                          <Link
                            aria-current="page"
                            className={`${slugFromUrl == "dashboard" ? "active" : ""
                              } sidebar-link`}
                            to="/dashboard"
                          >
                            <svg
                              width={20}
                              viewBox="0 0 122.88 121.135"
                              enableBackground="new 0 0 122.88 121.135"
                            >
                              <g>
                                <path d="M74.401,65.787h41.427c1.943,0,3.707,0.791,4.982,2.068c1.276,1.275,2.069,3.039,2.069,4.982v41.246 c0,1.941-0.793,3.707-2.069,4.98c-1.275,1.277-3.039,2.07-4.982,2.07H74.401c-1.942,0-3.706-0.793-4.982-2.07 c-1.275-1.273-2.068-3.039-2.068-4.98V72.838c0-1.943,0.793-3.707,2.068-4.982C70.695,66.578,72.459,65.787,74.401,65.787 L74.401,65.787z M7.052,0h41.426c1.942,0,3.707,0.792,4.983,2.069s2.068,3.04,2.068,4.983v41.245c0,1.943-0.792,3.707-2.068,4.982 c-1.276,1.276-3.041,2.069-4.983,2.069H7.052c-1.934,0-3.692-0.793-4.969-2.069l-0.007-0.006l-0.007,0.006 C0.792,52.003,0,50.239,0,48.296V7.052c0-1.943,0.792-3.707,2.069-4.983C2.162,1.976,2.26,1.888,2.359,1.807 C3.607,0.685,5.255,0,7.052,0L7.052,0z M48.131,7.397H7.397V47.95h40.733V7.397L48.131,7.397z M74.401,0h41.427 c1.943,0,3.707,0.792,4.982,2.069c1.276,1.276,2.069,3.04,2.069,4.983v41.245c0,1.943-0.793,3.707-2.069,4.982 c-1.275,1.276-3.039,2.069-4.982,2.069H74.401c-1.942,0-3.706-0.793-4.982-2.069c-1.275-1.275-2.068-3.04-2.068-4.982V7.052 c0-1.943,0.793-3.707,2.068-4.983C70.695,0.792,72.459,0,74.401,0L74.401,0z M115.482,7.397H74.748V47.95h40.734V7.397 L115.482,7.397z M7.052,65.787h41.426c1.942,0,3.707,0.791,4.983,2.068c1.276,1.275,2.068,3.039,2.068,4.982v41.246 c0,1.941-0.792,3.707-2.068,4.98c-1.276,1.277-3.041,2.07-4.983,2.07H7.052c-1.934,0-3.692-0.793-4.969-2.07l-0.007-0.006 l-0.007,0.006C0.792,117.791,0,116.025,0,114.084V72.838c0-1.943,0.792-3.707,2.069-4.982c0.093-0.094,0.191-0.182,0.291-0.264 C3.607,66.471,5.255,65.787,7.052,65.787L7.052,65.787z M48.131,73.184H7.397v40.553h40.733V73.184L48.131,73.184z M115.482,73.184 H74.748v40.553h40.734V73.184L115.482,73.184z" />
                              </g>
                            </svg>
                            {t("common.dashboard")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/tools"}
                            className={`${dropdowno ||
                                firstSlashValue == "/tools/" ||
                                slugFromUrl == "tools"
                                ? "active"
                                : ""
                              } sidebar-link parent-link`}
                            onClick={() => setDropdowno(!dropdowno)}
                          >
                            <svg width={20} viewBox="0 0 122.88 121.135">
                              <path d="M120.62,39.52,63.1,68.12a3.75,3.75,0,0,1-3.33,0L2.1,39.45a3.78,3.78,0,0,1-.18-6.67L59.59.48a3.78,3.78,0,0,1,3.73,0L121,32.78a3.77,3.77,0,0,1-.33,6.74Zm-9.77,40.93a3.76,3.76,0,0,1,3.6-6.61l6.41,3.38a3.77,3.77,0,0,1,1.58,5.09A3.82,3.82,0,0,1,120.7,84L63.1,112.6a3.75,3.75,0,0,1-3.33,0L2.1,83.93a3.77,3.77,0,0,1-1.71-5A3.69,3.69,0,0,1,2,77.22l6-3.14a3.76,3.76,0,0,1,4,6.35L61.44,105l49.41-24.57ZM111,57.69a3.76,3.76,0,0,1,4.36-6l5.49,2.89a3.76,3.76,0,0,1-.16,6.74L63.1,89.92a3.75,3.75,0,0,1-3.33,0L2.1,61.25a3.78,3.78,0,0,1-1.71-5A3.72,3.72,0,0,1,2,54.54L7.9,51.43A3.77,3.77,0,0,1,12,57.74l49.47,24.6L111,57.69ZM61.44,60.54,111,35.87,61.44,8.09,11.83,35.87,61.44,60.54Z" />
                            </svg>
                            <span className="linkTitle">
                              <span>
                                {t("common.tools")}
                              </span>{" "}
                              <span className="count">{total}</span>
                            </span>
                            <span className="arrow-icon down" />
                          </Link>
                          {(dropdowno ||
                            firstSlashValue == "/tools/" ||
                            slugFromUrl == "tools") && (
                              <ul>
                                <li>
                                  <Link
                                    className="sidebar-link"
                                    to="/tools/blog-content"
                                  >
                                    {t("common.blogContent")}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="sidebar-link"
                                    to="/tools/website-seo"
                                  >
                                    {t("common.websiteSEO")}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="sidebar-link"
                                    to="/tools/social-media"
                                  >
                                    {t("common.socialMedia")}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="sidebar-link"
                                    to="/tools/marketing"
                                  >
                                    {t("common.marketing")}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="sidebar-link"
                                    to="/tools/other"
                                  >
                                    {t("common.Others")}
                                  </Link>
                                </li>

                              </ul>
                            )}
                        </li>

                        <li>
                          <Link
                            onClick={() =>
                              setDropdownTemplete(!dropdownTemplete)
                            }
                            className="sidebar-link parent-link "
                          >
                            <svg
                              width="40px"
                              x="0px"
                              y="0px"
                              viewBox="0 0 111.26 122.88"
                              enableBackground="new 0 0 111.26 122.88"
                            >
                              <g>
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M93.37,19.34h10.43c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27v88.62 c0,2.06-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19H25.35c-2.05,0-3.92-0.84-5.27-2.19c-1.35-1.35-2.19-3.21-2.19-5.27 v-11.88H7.46c-2.05,0-3.92-0.84-5.27-2.19C0.84,100,0,98.13,0,96.08V7.46C0,5.4,0.84,3.54,2.19,2.19C3.54,0.84,5.4,0,7.46,0h78.45 c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27V19.34L93.37,19.34L93.37,19.34z M48.45,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.89,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C51.95,42.92,50.37,44.5,48.45,44.5L48.45,44.5L48.45,44.5z M32.42,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.9,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C35.92,42.92,34.34,44.5,32.42,44.5L32.42,44.5L32.42,44.5z M60.47,41.35 c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h6.94c1.72,0,3.11,1.39,3.11,3.11c0,1.72-1.4,3.11-3.11,3.11H60.47 L60.47,41.35z M24.59,61.06c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19c1.72,0,3.11,1.4,3.11,3.11 c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,61.06z M24.59,79.46c-1.72,0-3.11-1.4-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19 c1.72,0,3.11,1.4,3.11,3.11c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,79.46z M24.28,103.54v11.88c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V26.8c0-0.29-0.12-0.56-0.32-0.75 c-0.2-0.2-0.46-0.32-0.75-0.32H93.37v70.34c0,2.05-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19L24.28,103.54L24.28,103.54 L24.28,103.54z M85.91,6.39H7.46c-0.29,0-0.56,0.12-0.75,0.32c-0.2,0.2-0.32,0.46-0.32,0.75v88.62c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V7.46c0-0.29-0.12-0.56-0.32-0.75 C86.47,6.51,86.2,6.39,85.91,6.39L85.91,6.39L85.91,6.39z"
                                />
                              </g>
                            </svg>
                            <span className="linkTitle">
                              <span>
                                {t("common.templates")}
                              </span>
                              <span className="count">{totaltemp}</span>
                            </span>
                            <span className="arrow-icon down" />
                          </Link>
                          <ul
                            style={{
                              display: `${!dropdownTemplete ? "none" : "block"
                                }`,
                            }}
                          >
                            <li>
                              <Link
                                className="sidebar-link"
                                to="/tools/emails-templates"
                              >
                                {t("common.emails")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="sidebar-link"
                                to="/tools/personal-templates"
                              >
                                {t("common.personal")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="sidebar-link"
                                to="/tools/hr-templates"
                              >
                                {CurrentLang == "en" ? "HR" : "менеджер"}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="sidebar-link"
                                to="/tools/marketing-templates"
                              >
                                {t("common.marketing")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="sidebar-link"
                                to="/tools/careers-templates"
                              >
                                {t("common.careers")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            aria-current="page"
                            className={`${slugFromUrl == "chat" ? "active" : ""
                              } sidebar-link`}
                            to="/chat"
                          >
                            <svg
                              fill="#000000"
                              width={20}
                              version="1.1"
                              id="Capa_1"
                              viewBox="0 0 60 60"
                            >
                              <g>
                                <path
                                  d="M44.348,12.793H2.652C1.189,12.793,0,13.982,0,15.445v43.762l9.414-9.414h34.934c1.463,0,2.652-1.19,2.652-2.653V15.445
		C47,13.982,45.811,12.793,44.348,12.793z M10,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S12.206,35.777,10,35.777z
		 M23,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S25.206,35.777,23,35.777z M36,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4
		s4,1.794,4,4S38.206,35.777,36,35.777z"
                                />
                                <path
                                  d="M57.348,0.793H12.652C11.189,0.793,10,1.982,10,3.445v7.348h34.348c2.565,0,4.652,2.087,4.652,4.652v25.332h11V3.445
		C60,1.982,58.811,0.793,57.348,0.793z"
                                />
                              </g>
                            </svg>
                            {t("AIChat.label")}
                          </Link>
                        </li>
                        <li>
                          <Link className="sidebar-link" to="/my-content">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="16px"
                              height="16px"
                              viewBox="0 0 512.001 512.001"
                              enableBackground="new 0 0 512.001 512.001;"
                              fill="#6a3dfd"
                            >
                              <g>
                                <g>
                                  <g>
                                    <path
                                      d="M492.971,211.64h-50.443v-63.41c0-10.502-8.514-19.016-19.016-19.016H189.695V63.91c0-10.502-8.514-19.016-19.016-19.016
                      H19.016C8.514,44.894,0,53.408,0,63.91c0,13.692,0.001,370.375,0.001,384.179c0,10.432,8.48,19.017,19.029,19.017
                      c0.01,0,0.02-0.001,0.03-0.001h404.453c8.273,0,15.597-5.349,18.115-13.23l69.459-217.435
                      C515.005,224.175,505.831,211.64,492.971,211.64z M38.032,82.926h113.631v46.288H38.032V82.926z M38.032,167.247
                      c8.343,0,359.629-0.001,366.464-0.001v44.394H88.489c-8.272,0-15.596,5.349-18.115,13.229L38.032,326.092V167.247z
                      M409.625,429.073H45.057l57.321-179.403c11.05,0,346.991,0,364.557,0L409.625,429.073z"
                                    />
                                    <path
                                      d="M206.005,358.389h30.973v30.973c0,10.502,8.514,19.016,19.016,19.016c10.502,0,19.016-8.514,19.016-19.016v-30.973
                      h30.973c10.502,0,19.016-8.514,19.016-19.016c0-10.503-8.514-19.016-19.016-19.016H275.01v-30.973
                      c0-10.502-8.514-19.016-19.016-19.016c-10.502,0-19.016,8.514-19.016,19.016v30.973h-30.973
                      c-10.502,0-19.016,8.514-19.016,19.016C186.989,349.875,195.503,358.389,206.005,358.389z"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                              <g />
                            </svg>
                            {t("common.myContent")}
                          </Link>
                        </li>
                      </ul>
                    </nav>

                    {/* /////// Favroutees ..... */}
                    {Favroute.length > 0 && (
                      <>
                        <div className="subHead">
                        {t("common.favorites")}
                        </div>

                        <ul>
                          {Favroute.map((fav, ind) => (
                            <li key={ind}>
                              <div
                                onClick={() =>
                                  navigate("/" + fav.slug, {
                                    state: { v: fav },
                                  })
                                }
                                className="sidebar-link"
                              >
                                {fav.image != "" && <img src={fav.image} />}
                                {fav?.title}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}

                    {/* -------------------- */}

                    <div className="subHead">
                      {t("common.getStarted")}
                    </div>
                    <nav>
                      <ul>
                        {data.map((v, i) => (
                          <>
                            {/* <li>
                              <div
                                onClick={() => handleRoute(v)}
                                className="sidebar-link"
                              >
                                {v.image != "" && <img src={v.image} />}
                                {v.title}
                              </div>
                            </li> */}
                          </>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="nav-item show dropdown">
              <Link
                id="basic-nav-dropdown"
                aria-expanded="true"
                role="button"
                className="dropdown-toggle nav-link "
                tabIndex={0}
                onClick={() => setDropdown(!dropdown)}
              >
                <span className="profileImgWrap">
                  <span className="profile-img " style={{ background: "#fff" }}>
                    <img
                      src={getLoggedObject().profile_pic ? propic: dummyUserImage}
                      alt="profile"
                      width="100%"
                      style={{
                        width: "36px",
                        height: "36px",
                      }}
                    />
                  </span>
                </span>
              </Link>
              <div
                id="dropdown-menu-id"
                aria-labelledby="basic-nav-dropdown"
                data-bs-popper="static"
                className={
                  dropdown
                    ? "dropdown-menu  dropdown-menu-end show"
                    : "dropdown-menu  dropdown-menu-end "
                }
              >
                <Link
                  aria-selected="false"
                  data-rr-ui-dropdown-item=""
                  className="dropdown-item "
                  role="button"
                  tabIndex={0}
                  to="/profile"
                  onClick={() => setDropdown(false)}
                >
                  <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                  >
                    <g>
                      <path
                        d="M32,42c8.271,0,15-8.523,15-19S40.271,4,32,4s-15,8.523-15,19S23.729,42,32,42z M32,8c5.963,0,11,6.869,11,15
            s-5.037,15-11,15s-11-6.869-11-15S26.037,8,32,8z"
                      />
                      <path
                        d="M4.103,45.367l-4,12c-0.203,0.61-0.101,1.28,0.275,1.802C0.753,59.691,1.357,60,2,60h60c0.643,0,1.247-0.309,1.622-0.831
            c0.376-0.521,0.479-1.191,0.275-1.802l-4-12c-0.209-0.626-0.713-1.108-1.348-1.29l-14-4c-0.482-0.139-0.996-0.09-1.444,0.134
            L32,45.764l-11.105-5.553c-0.448-0.224-0.962-0.272-1.444-0.134l-14,4C4.815,44.259,4.312,44.741,4.103,45.367z M19.802,44.137
            l11.304,5.652c0.562,0.281,1.227,0.281,1.789,0l11.304-5.652l12.238,3.496L59.226,56H4.774l2.789-8.367L19.802,44.137z"
                      />
                    </g>
                  </svg>
                  {t("common.profile")}
                </Link>
                <Link
                  aria-selected="false"
                  data-rr-ui-dropdown-item=""
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                  to="/pricing"
                  onClick={() => setDropdown(false)}
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    width="24px"
                    height="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 398.923 398.923"
                    enableBackground="new 0 0 398.923 398.923"
                  >
                    <g>
                      <path
                        d="M398.425,131.075c-0.826-2.52-2.621-4.609-4.986-5.806l-8.923-4.516c-2.366-1.197-5.112-1.407-7.632-0.58
            c-2.52,0.826-4.609,2.62-5.806,4.986l-11.255,22.239c-6.629-22.075-17.601-42.652-32.415-60.567
            c-21.099-25.516-48.973-44.391-80.607-54.585c-17.4-5.608-35.377-8.451-53.43-8.451c-27.576,0-54.155,6.456-78.999,19.19
            c-41.265,21.15-71.823,57.103-86.047,101.237c-1.694,5.256,1.194,10.891,6.451,12.585l9.518,3.067
            c5.259,1.696,10.892-1.194,12.585-6.451c11.766-36.506,37.044-66.247,71.178-83.741c20.563-10.539,42.542-15.883,65.327-15.883
            c14.927,0,29.805,2.355,44.217,7c47.243,15.225,82.96,53.828,95.083,101.102l-28.955-14.653
            c-4.926-2.496-10.943-0.521-13.438,4.407l-4.516,8.923c-1.198,2.367-1.406,5.112-0.58,7.632c0.826,2.52,2.62,4.609,4.987,5.806
            l62.565,31.661c1.447,0.733,2.988,1.08,4.507,1.08c3.65,0,7.169-2.006,8.93-5.486l31.662-62.564
            C399.043,136.34,399.251,133.595,398.425,131.075z"
                      />
                      <path
                        d="M364.148,242.116l-9.518-3.067c-5.257-1.695-10.891,1.194-12.585,6.451c-11.766,36.506-37.043,66.247-71.177,83.741
            c-20.563,10.539-42.543,15.882-65.329,15.882c-14.928,0-29.804-2.354-44.215-6.999c-47.243-15.225-82.959-53.828-95.083-101.102
            l28.955,14.653c4.927,2.496,10.943,0.521,13.438-4.407l4.516-8.923c1.198-2.367,1.406-5.112,0.58-7.632
            c-0.827-2.52-2.62-4.609-4.987-5.807l-62.565-31.66c-4.925-2.495-10.943-0.521-13.438,4.407L1.078,260.216
            c-1.198,2.367-1.406,5.112-0.58,7.632c0.826,2.52,2.62,4.609,4.986,5.806l8.923,4.516c4.928,2.495,10.944,0.521,13.438-4.407
            L39.1,251.524c6.629,22.076,17.601,42.652,32.415,60.567c21.099,25.516,48.972,44.391,80.606,54.585
            c17.4,5.608,35.376,8.451,53.429,8.451c27.188,0,54.506-6.636,79-19.19c41.265-21.15,71.823-57.103,86.047-101.237
            c0.814-2.524,0.591-5.268-0.619-7.628C368.77,244.712,366.672,242.93,364.148,242.116z"
                      />
                      <path
                        d="M200.753,238.729c-9.333,0-19.42-4.033-25.101-10.035l-4.468-4.721c-1.137-1.201-3.036-1.256-4.241-0.117l-16.706,15.81
            c-0.577,0.548-0.914,1.302-0.937,2.097c-0.021,0.795,0.273,1.566,0.82,2.145l4.469,4.722c7.706,8.141,18.909,14.354,31.031,17.265
            v16.568c0,1.656,1.343,3,3,3h23c1.657,0,3-1.344,3-3V266.48c21.662-4.13,34.999-18.093,34.999-36.887
            c0.245-29.6-25.542-37.409-42.605-42.577c-20.501-6.209-23.598-9.316-23.598-16.775c0-7.931,13.087-10.047,20.82-10.047
            c7.346,0,16.425,2.421,21.588,5.756l5.46,3.527c1.393,0.898,3.249,0.498,4.147-0.893l12.479-19.318
            c0.9-1.393,0.5-3.249-0.892-4.148l-5.459-3.527c-7.256-4.688-16.952-8.128-26.941-9.602v-15.527c0-1.656-1.343-3-3-3h-23
            c-1.657,0-3,1.344-3,3v17.115c-19.313,5.345-31.203,19.221-31.203,36.664c0,31.145,27.662,39.523,44.191,44.53
            c19.329,5.854,22.064,8.39,22.011,14.702C220.621,236.78,208.132,238.729,200.753,238.729z"
                      />
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                  {t("common.pricingPlans")}
                </Link>

                <Link
                  aria-selected="false"
                  data-rr-ui-dropdown-item=""
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                  to="/change-password"
                  onClick={() => setDropdown(false)}
                >
                  <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                  >
                    <g>
                      <path
                        d="M32,42c8.271,0,15-8.523,15-19S40.271,4,32,4s-15,8.523-15,19S23.729,42,32,42z M32,8c5.963,0,11,6.869,11,15
            s-5.037,15-11,15s-11-6.869-11-15S26.037,8,32,8z"
                      />
                      <path
                        d="M4.103,45.367l-4,12c-0.203,0.61-0.101,1.28,0.275,1.802C0.753,59.691,1.357,60,2,60h60c0.643,0,1.247-0.309,1.622-0.831
            c0.376-0.521,0.479-1.191,0.275-1.802l-4-12c-0.209-0.626-0.713-1.108-1.348-1.29l-14-4c-0.482-0.139-0.996-0.09-1.444,0.134
            L32,45.764l-11.105-5.553c-0.448-0.224-0.962-0.272-1.444-0.134l-14,4C4.815,44.259,4.312,44.741,4.103,45.367z M19.802,44.137
            l11.304,5.652c0.562,0.281,1.227,0.281,1.789,0l11.304-5.652l12.238,3.496L59.226,56H4.774l2.789-8.367L19.802,44.137z"
                      />
                    </g>
                  </svg>
                  {t("common.changePassword")}
                </Link>

                <Link
                  to={"https://aiword.ru/contact"}
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 496.804 496.804"
                  >
                    <g id="XMLID_129_">
                      <path
                        id="XMLID_132_"
                        d="M467.585,433.167c-4.972-12.719-17.23-21.089-30.888-21.089H51.416c-13.659,0-25.917,8.37-30.891,21.089
 L3.069,477.834c-1.674,4.278-1.125,9.104,1.472,12.905c2.587,3.793,6.89,6.065,11.482,6.065h456.066
 c4.593,0,8.895-2.272,11.482-6.065c2.594-3.801,3.146-8.627,1.472-12.905L467.585,433.167z"
                      ></path>
                      <path
                        id="XMLID_131_"
                        d="M64.353,377.85H423.75c18.315,0,33.162-14.846,33.162-33.159V190.167h-33.137L423.75,344.73l-359.439-0.04
 l0.042-231.253h160.911v-33.12H64.353c-18.315,0-33.162,14.846-33.162,33.161V344.69C31.191,363.004,46.037,377.85,64.353,377.85z"
                      ></path>
                      <path
                        id="XMLID_130_"
                        d="M465.862,0h-178.65c-15.923,0-28.828,12.904-28.828,28.826v99.393c0,15.922,12.905,28.826,28.828,28.826
 h41.974l-17.838,48.008c-0.47,1.268-0.099,2.7,0.938,3.573c0.598,0.51,1.342,0.775,2.094,0.775c0.542,0,1.091-0.137,1.586-0.42
 l91.808-51.937h58.088c15.923,0,28.828-12.904,28.828-28.826V28.826C494.69,12.904,481.784,0,465.862,0z"
                      ></path>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <span>{t("common.contactUs")}</span>
                </Link>

                <Link
                  onClick={() => setWatchVideo(true)}
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                >
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 122.88 111.34"
                  >
                    <path d="M23.59,0h75.7a23.68,23.68,0,0,1,23.59,23.59V87.75A23.56,23.56,0,0,1,116,104.41l-.22.2a23.53,23.53,0,0,1-16.44,6.73H23.59a23.53,23.53,0,0,1-16.66-6.93l-.2-.22A23.46,23.46,0,0,1,0,87.75V23.59A23.66,23.66,0,0,1,23.59,0ZM54,47.73,79.25,65.36a3.79,3.79,0,0,1,.14,6.3L54.22,89.05a3.75,3.75,0,0,1-2.4.87A3.79,3.79,0,0,1,48,86.13V50.82h0A3.77,3.77,0,0,1,54,47.73ZM7.35,26.47h14L30.41,7.35H23.59A16.29,16.29,0,0,0,7.35,23.59v2.88ZM37.05,7.35,28,26.47H53.36L62.43,7.38v0Zm32,0L59.92,26.47h24.7L93.7,7.35Zm31.32,0L91.26,26.47h24.27V23.59a16.32,16.32,0,0,0-15.2-16.21Zm15.2,26.68H7.35V87.75A16.21,16.21,0,0,0,12,99.05l.17.16A16.19,16.19,0,0,0,23.59,104h75.7a16.21,16.21,0,0,0,11.3-4.6l.16-.18a16.17,16.17,0,0,0,4.78-11.46V34.06Z" />
                  </svg>
                  {t("common.watchTutorialVideo")}
                </Link>

                <Link
                  to={"/helpcenter"}
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                >
                  <MdHelpOutline size={25} />
                  {t("common.helpCenter")}
                </Link>

                <Link
                  data-rr-ui-dropdown-item=""
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                  onClick={() => do_logout()}
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 320.002 320.002"
                  >
                    <g id="XMLID_6_">
                      <path
                        id="XMLID_7_"
                        d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
            c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
            c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
            c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
            c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
            c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
            c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
            c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
            c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
            c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
            c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
            c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
            c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
            z"
                      />
                      <path
                        id="XMLID_8_"
                        d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
            v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
            C320.002,31.716,313.286,25,305.002,25z"
                      />
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>{" "}
                  <span>{t("common.logOut")}</span>
                </Link>
              </div>
            </div>
            {/* <div
                  className="language_switcher"
                  style={{ marginRight: "0px" }}
                  onClick={() => setDropLang(!droplang)}
                >
                  <img src={currentLanguage === "en" ? ENGLISH : RUSSIAN} alt="Language Icon" />
                  {droplang && (
                    <div className="switcher_dropdown">
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("en")}
                      >
                        <img src={ENGLISH} alt="English Icon" />
                        <span>{t("common.english")}</span>
                      </div>
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("ru")}
                      >
                        <img src={RUSSIAN} alt="Russian Icon" />
                        <span>{t("common.russian")}</span>
                      </div>
                    </div>
                  )}
                </div> */}
            <div className="mob_switcher" style={{ paddingBottom: "180px" }}>
              <div
                className="language_switcher "
                onClick={() => setDropLang(!droplang)}
              >
               <img src={currentLanguage === "en" ? ENGLISH : RUSSIAN} alt="Language Icon" />
                {currentLanguage == "en" ? "English" : "Russian"}
                  {droplang && (
                    <div className="switcher_dropdown">
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("en")}
                      >
                        <img src={ENGLISH} alt="English Icon" />
                        <span>{t("common.english")}</span>
                      </div>
                      <div
                        className=""
                        onClick={() => handleChangeLanguage("ru")}
                      >
                        <img src={RUSSIAN} alt="Russian Icon" />
                        <span>{t("common.russian")}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default NavBar;

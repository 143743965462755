import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HEADER from "../../base-components/Header/header_login";
import { Translator, Translate } from "react-auto-translate";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Otp() {
  const navigate = useNavigate();
  const params = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const { t } = useTranslation();
  const do_action = async () => {
    if (email == "") {
      toast.error(NotificationsToasts?.enter_otp_code?.notification);
      return;
    }
    const payload = {
      otp: email,
      user_email: params.state.user_email,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/verify_otp_code");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success(NotificationsToasts?.otp_verified?.notification);
        setShowPassword(true);
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  const do_update_password = async () => {
    if (password == "") {
      toast.error(NotificationsToasts?.enter_password?.notification);
      return;
    }
    if (cpassword == "") {
      toast.error(NotificationsToasts?.enter_confirm_password?.notification);
      return;
    }
    if (cpassword !== password) {
      toast.error(NotificationsToasts?.pass_confirm_password_not_matching?.notification);
      return;
    }
    const payload = {
      otp: email,
      user_email: params.state.user_email,
      password: password,
      cpassword: cpassword,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/update_forgot_password"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success(NotificationsToasts?.password_updated_successfully?.notification);
        setShowPassword(false);
        navigate("/login");
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  return (
    <>
      {!showpassword && (
        <>
          {/* <div className="login-container">
                    <h1>OTP Code</h1>
                    <p className="mb-20">Enter your OTP Code!</p>
                    <div className="form-group">
                        <label>OTP Code:</label>
                        <input type="number" className="custom_input" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                   
                    <div className="form-group flex-space-between">
                        <span></span>
                        <button onClick={()=>do_action()} className="button_custom primary">Verify</button>
                    </div>
                </div> */}

          <div className="layout-container container-fluid">
            <HEADER />
            <div className="layout-row row">
              <div className="scrollable-body noSidebar col-md-12 col-12">
                <div className="container-wrappe">
                  <div className="col-4 p-0 card my-5 mx-auto loginForm card">
                    <div className="card-body">
                      <div className="p-4 box">
                        <h2>
                          {t("common.OTPCode")}
                        </h2>
                        <p>
                          {t("common.enterYourOTPCode")}
                        </p>
                        {/* <form className> */}
                        <div className="mb-4">
                          <input
                            placeholder="OTP Code"
                            type="number"
                            id="formBasicEmail"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="d-grid gap-2">
                          <button
                            type="Submit"
                            className="btn btn-primary"
                            onClick={() => do_action()}
                          >
                            {t("common.submit")}
                          </button>
                        </div>
                        {/* </form> */}
                      </div>
                      <div className="p-4 text-center">
                        <p>
                          <Link to="/login">
                            {t("common.backToSignIn")}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showpassword && (
        <>
          {/* <div className="login-container">
                    <h1>Reset password</h1>
                    <p className="mb-20">Please enter your new password to reset!</p>
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="password" className="custom_input" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                            <label>Confirm Password:</label>
                            <input type="password" className="custom_input" value={cpassword} onChange={(e) => setCPassword(e.target.value)} />
                    </div>
                    <div className="form-group flex-space-between">
                        <span></span>
                        <button onClick={()=>do_update_password()} className="button_custom primary">Submit</button>
                    </div>
                </div> */}

          <div className="layout-container container-fluid">
            <HEADER />
            <div className="layout-row row">
              <div className="scrollable-body noSidebar col-md-12 col-12">
                <div className="container-wrappe">
                  <div className="col-4 p-0 card my-5 mx-auto loginForm card">
                    <div className="card-body">
                      <div className="p-4 box">
                        <h2>
                          {t("common.resetPassword")}
                        </h2>
                        <p>
                          {t("common.pleaseEnterYourNewPasswordToReset")}
                        </p>
                        {/* <form className> */}
                        <div className="mb-4">
                          <input
                            placeholder="Password"
                            type="password"
                            id="formBasicEmail"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            placeholder="Confirm Password"
                            type="password"
                            id="formBasicEmail"
                            className="form-control"
                            value={cpassword}
                            onChange={(e) => setCPassword(e.target.value)}
                          />
                        </div>
                        <div className="d-grid gap-2">
                          <button
                            type="Submit"
                            className="btn btn-primary"
                            onClick={() => do_update_password()}
                          >
                            {t("common.submit")}
                          </button>
                        </div>
                        {/* </form> */}
                      </div>
                      <div className="p-4 text-center">
                        <p>
                          <Link to="/login">
                            {t("common.backToSignIn")}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Otp;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Translate } from "react-auto-translate";
import { doPost } from "./../../../utils/apiCalls";
import useAuth from "./../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SideBarDetiails = () => {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const location = useLocation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language
  const handleRoute = (slug) => {
    navigate(`/helpcenter/${slug}`);
  };

  const Get_Articles = async () => {
    const payload = {
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/helpCenter");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    Get_Articles();
  }, []);

  return (
    <div className="w-100 p-2">
      <ul>
        {loading && <div className="loader" />}
        {Data.map((item, index) => {
          return (
            <li
              style={{
                color: `${
                  location.pathname === `/helpcenter/${item?.slug}`
                    ? "#1fad24"
                    : ""
                }`,
              }}
              className={`HelpList `}
              onClick={() => handleRoute(item?.slug)}
              key={index}
            >
              <span>{currentLanguage === 'ru' ? item?.title_in_russian : item?.title }</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideBarDetiails;

// import ReactDOM from "react-dom/client";
// import React, { useEffect, useState, useRef } from "react";
// import useAuth from "./../../hooks/useAuth";
// import {
//   doPost,
//   OPENAPI,
//   CountWords,
//   handleCopyClick,
//   handleSaveContent,
//   do_update_token,
// } from "./../../utils/apiCalls";
// import {Translate } from "react-auto-translate";
// import COPYICON from "./../../assets/images/copy_icon.svg";
// import DELICON from "./../../assets/images/del_icon.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { setTokens } from "../../lib/Redux/slices/userslice";
// import { useTranslation } from "react-i18next";

// const ImageResponse = ({ loading, responseAI, contentCategory, image_data }) => {
//   const { NotificationsToasts = null } = useSelector((state) => state.user);
//   const { t } = useTranslation()
//   const dispatch = useDispatch();
//   const { Tokenvalue = 0 } = useSelector((state) => state.web);
//   const [newresponseAI, setNewResponseAI] = useState([]);
//   const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
//     useAuth();
//   const do_update_response = (v) => {
//     if (responseAI.length > 1) {
//       const new_content = v;

//       return new_content;
//     } else {
//       return v;
//     }
//   };

//   useEffect(() => {
//     setNewResponseAI(responseAI);
//   }, [responseAI]);
  
//   useEffect(() => {
//     const fetch = async () => {
//       let last = responseAI[responseAI.length - 1];
//       let length = CountWords(last);
//   const response = await do_update_token(length, getLoggedObject()?.token ,NotificationsToasts );
//       if (response) {
//         dispatch(setTokens(response));
//       }
//     };
//     fetch();
//   }, [responseAI]);

//   const do_update_ai_content = async (ind, event) => {
//     const updatedResponseAI = [...newresponseAI];
//     updatedResponseAI[ind] = event;
//     setNewResponseAI(updatedResponseAI);
//   };

//   return (
//     <>
//       <div className="card tool_car_right_response">
//         <div className="ai-board-header card-title h5" />
//         <div className="ai-board-body card-body">
//           {loading && (
//             <div className="genMoreLoad">
//               <div className="loaderWrap">
//                 <strong>
//                   {t("common.generatingContent")}...
//                 </strong>
//                 <p>
//                   {t("common.pleaseWaitAFewSeconds")}
//                 </p>
//                 <div className="loader"></div>
//               </div>
//             </div>
//           )}
//           {!loading && newresponseAI == "" && (
//             <div className="noResults">
//               <h5>
//                 <i>🚀</i> {t("common.getStartNow")}...
//               </h5>
//               <p>
//                 {t("common.generateHighQualityContent")}
//               </p>
//             </div>
//           )}

//           {newresponseAI != "" && (
//             <>
//               {newresponseAI.map((v, i) => (
//                 <div
//                   className="ai-section height-align text-center"
//                   key={"response_" + i}
//                 >
//                   <div className="ai-content">
//                     <div>
//                       <div
//                         className="editableContent"
//                         contenteditable="true"
//                         style={image_data == 1 ? { textAlign: "center" } : {}}
//                         onBlur={(event) => {
//                           // event.preventDefault();
//                           do_update_ai_content(
//                             i,
//                             event.currentTarget.innerText
//                           );
//                         }}
//                       >
//                         {image_data == 1 ? (
//                           <>
//                             <img src={v} style={{ width: "400px" }} />
//                           </>
//                         ) : (
//                           do_update_response(v)
//                         )}
//                       </div>
//                     </div>
//                     <span className="index">{i + 1}</span>
//                     <div className="btn-group-wrap text-right">
//                       <div className="float-left display-flex">
//                         {image_data == 1 ? (
//                           <></>
//                         ) : (
//                           <span className="wordCount float-left">
//                             {t("common.words")}: {CountWords(v)}
//                           </span>
//                         )}
//                       </div>
//                       <div
//                         className="svg_button"
//                         style={{ pointerEvents: "auto" }}
//                       >
//                         <button
//                           onClick={() => handleCopyClick(v ,NotificationsToasts)}
//                           type="button"
//                           id={0}
//                           className="btn btn-outline-secondary btn-sm"
//                         >
//                           <img src={COPYICON} />{" "}
//                           <span>
//                             {t("common.copy")} {image_data == 1 ? "Image Url" : ""}
//                           </span>{" "}
//                         </button>
//                         {image_data == 1 ? (
//                           <></>
//                         ) : (
//                           <button
//                             type="button"
//                             id={0}
//                             className="ml-1  btn btn-outline-secondary btn-sm"
//                             onClick={() =>
//                               handleSaveContent(
//                                 v,
//                                 contentCategory,
//                                 CountWords(v),
//                                 getLoggedObject()?.token,
//                                 NotificationsToasts
//                               )
//                             }
//                           >
//                             <img src={DELICON} /> {t("common.save")}
//                           </button>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ImageResponse;


import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import {
  doPost,
  OPENAPI,
  CountWords,
  handleCopyClick,
  handleSaveContent,
  do_update_token,
} from "./../../utils/apiCalls";
import { Translate } from "react-auto-translate";
import COPYICON from "./../../assets/images/copy_icon.svg";
import DELICON from "./../../assets/images/del_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../../lib/Redux/slices/userslice";
import { useTranslation } from "react-i18next";

const ImageResponse = ({ loading, responseAI, contentCategory, image_data }) => {
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Tokenvalue = 0 } = useSelector((state) => state.web);
  const [newresponseAI, setNewResponseAI] = useState([]);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();

  const do_update_response = (v) => {
    if (responseAI.length > 1) {
      const new_content = v;
      return new_content;
    } else {
      return v;
    }
  };

  useEffect(() => {
    setNewResponseAI(responseAI);
  }, [responseAI]);

  useEffect(() => {
    const fetch = async () => {
      let last = responseAI[responseAI.length - 1];
      let length = CountWords(last);
      const response = await do_update_token(length, getLoggedObject()?.token, NotificationsToasts);
      if (response) {
        dispatch(setTokens(response));
      }
    };
    fetch();
  }, [responseAI]);

  const do_update_ai_content = async (ind, event) => {
    const updatedResponseAI = [...newresponseAI];
    updatedResponseAI[ind] = event;
    setNewResponseAI(updatedResponseAI);
  };

  return (
    <>
      <div className="card tool_car_right_response">
        <div className="ai-board-header card-title h5" />
        <div className="ai-board-body card-body">
          {loading && (
            <div className="genMoreLoad">
              <div className="loaderWrap">
                <strong>{t("common.generatingContent")}...</strong>
                <p>{t("common.pleaseWaitAFewSeconds")}</p>
                <div className="loader"></div>
              </div>
            </div>
          )}
          {!loading && newresponseAI.length === 0 && (
            <div className="noResults">
              <h5>
                <i>🚀</i> {t("common.getStartNow")}...
              </h5>
              <p>{t("common.generateHighQualityContent")}</p>
            </div>
          )}

          {newresponseAI.length > 0 && (
            <>
              {newresponseAI.map((v, i) => (
                <div className="ai-section height-align text-center" key={"response_" + i}>
                  <div className="ai-content">
                    <div>
                      <div
                        className="editableContent"
                        contentEditable="true"
                        style={image_data === 1 ? { textAlign: "center" } : {}}
                        onBlur={(event) => {
                          do_update_ai_content(i, event.currentTarget.innerText);
                        }}
                      >
                        {image_data === 1 ? (
                          <>
                            <img src={v.url} alt={`Generated Image ${i + 1}`} style={{ width: "400px" }} />
                          </>
                        ) : (
                          do_update_response(v)
                        )}
                      </div>
                    </div>
                    <span className="index">{i + 1}</span>
                    <div className="btn-group-wrap text-right">
                      <div className="float-left display-flex">
                        {image_data === 1 ? null : (
                          <span className="wordCount float-left">
                            {t("common.words")}: {CountWords(v)}
                          </span>
                        )}
                      </div>
                      <div className="svg_button" style={{ pointerEvents: "auto" }}>
                        <button
                          onClick={() => handleCopyClick(image_data === 1 ? v.url : v, NotificationsToasts)}
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                        >
                          <img src={COPYICON} alt="Copy Icon" />{" "}
                          <span>{t("common.copy")} {image_data === 1 ? "Image Url" : ""}</span>
                        </button>
                        {image_data === 1 ? null : (
                          <button
                            type="button"
                            className="ml-1  btn btn-outline-secondary btn-sm"
                            onClick={() =>
                              handleSaveContent(
                                v,
                                contentCategory,
                                CountWords(v),
                                getLoggedObject()?.token,
                                NotificationsToasts
                              )
                            }
                          >
                            <img src={DELICON} alt="Save Icon" /> {t("common.save")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageResponse;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBarLOGIN from "../../../base-components/NavBar/navbar_login";
import SideBar from "../../../base-components/SideBar";
import { Translate } from "react-auto-translate";
import SideBarDetiails from "../Sidebar";
import FAQItem from "../faq";
import { doPost } from "./../../../utils/apiCalls";
import useAuth from "./../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Details = () => {
  const { slug } = useParams();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language;
  const Get_Articles = async () => {
    setData([]);
    const payload = {
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      `auth/helpCenterDetails/${slug}`
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    Get_Articles();
  }, [slug]);

  return (
    <div className="layout-container container-fluid">
      <NavBarLOGIN />
      <div className="layout-row row">
        <SideBar />
        <div className="scrollable-body col-md-9 col-12">
          <h2>
            {t("common.helpCenter")}
          </h2>
          <div className="DetailsMain">
            <div className="SidebarDetails">
              <SideBarDetiails />
            </div>
            <div className="ContenMainDetails">
              {loading && <div className="loader" />}
              
              {Data &&
                Data.map((item, index) => {
                  return (
                    <FAQItem
                      key={index}
                      question={currentLanguage === 'ru' ? item?.question_in_russian : item?.question }
                      answer={currentLanguage === 'ru' ? item?.answer_in_russian : item?.answer }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;

import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DELETE from "./../../assets/images/delete.svg";

import {
  doPost,
  OPENAPI,
  CountWords,
  handleCopyClick,
} from "./../../utils/apiCalls";
import { Translator, Translate } from "react-auto-translate";
import Common from "./common";
import ResponseDiv from "./Response";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Inner({ data = [] }) {
  const { state } = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const [titleab, setTitleab] = useState(state?.v?.title);
  const [descab, setDesceab] = useState(state?.v.description);

  const [steps, setSteps] = useState(1);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);

  const [brandname, setBrandName] = useState(data?.data[1]?.textfield || "");
  const [description, setDescription] = useState(
    data?.data[0]?.textfield || ""
  );

  const [language, setLanguage] = useState("Russian");
  const [variant, setVariant] = useState(1);
  const [tone, setTone] = useState("Formal");
  const [creativity, setCreativity] = useState("1");
  const [responseAI, setResponseAI] = useState([]);
  const [introAI, setIntroAI] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [outlineAI, setOutlineAI] = useState([]);
  const [type, setType] = useState("amazon_product_bullets");
  const { t } = useTranslation();

  const [titleselected, setTitleSelected] = useState(data?.data[3]?.textfield || "");
  const [outlineText, setOutlineText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // CREATE TITLES
  const do_make_action = async (val = 0) => {
    if (description == "") {
      toast.error(NotificationsToasts?.write_blog_post_idea_to_create?.notification);
      return;
    }
    for (let i = 0; i < 10; i++) {
      do_create_titles(val);
    }
  };
  const do_create_titles = async (val) => {
    if (val == 1) {
      setResponseAI([]);
    }
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;
    if (brandname != "") {
      var to_u = t("common.andKeywordsAre") + brandname;
    } else {
      var to_u = "";
    }

    const content_submit =
      `${t("common.generateABlogTitleBased")} (` +
      description +
      ") " +
      to_u;
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: t("common.responseShouldBeIn") + language },
      // { role: 'system', content: `You should use a ${tone} tone.` },
    ];
    // console.log(payload);
    // return;
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setResponseAI((prevResponse) => [...prevResponse, ...lines]);
    setSteps(2);
  };

  const do_select_title = async (val, index) => {
    if (val == "") {
      toast.error(NotificationsToasts?.select_enter_title?.notification);
      return;
    }
    setTitleSelected(val);
    setIntroAI([]);
    setSteps(3);
  };
  const do_select_intro = async (val, index) => {
    setOutlineText(val);
    setSteps(4);
  };

  // GENERATE INTROS
  const do_make_intro = async () => {
    for (let i = 0; i < 3; i++) {
      do_create_intros();
    }
  };
  const do_create_intros = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;
    const content_submit =
      `${t("common.generateABlogIntro")} (` +
      titleselected +
      ") ";
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: t("common.responseShouldBeIn") + language },
    ];
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setIntroAI((prevResponse) => [...prevResponse, ...lines]);
  };

  // GENERATE OUTLINES
  const do_make_headings = async () => {
    setOutlineAI([]);
    // for (let i = 0; i < 10; i++) {
    do_create_headings();
    // }
  };
  const do_create_headings = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;
    const content_submit =
      `${t("common.generateTopLevelHeadings")} (` +
      titleselected +
      `) ${t("common.andBlogIntroIs")} (` +
      outlineText +
      ") ";

    // console.log(content_submit);return;
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: t("common.responseShouldBeIn") + language },
    ];
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = final_response.split("\n");
    setOutlineAI(lines);
    // setOutlineAI(prevResponse => [...prevResponse, ...lines]);
  };

  const do_save_heading_to_fetch = (item) => {
    const index = selectedItems.indexOf(item);
    if (index === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
    }
  };

  // GENERATE FULL CONTENT
  const generate_full_content = async () => {
    if (selectedItems.length == 0) {
      toast.error(
        NotificationsToasts?.enter_one_more_subheadings?.notification
      );
      return;
    }
    do_create_content();
  };

  const do_create_content = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;

    var all_sub_headings = selectedItems.map((item) => item).join("\n");
    const content_submit =
      `${t("common.generateAFullBlogPost")} (` +
      titleselected +
      `) ${t("common.andOutlineHeadingsAre")} (` +
      all_sub_headings +
      introAI +
      ") ";
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: t("common.responseShouldBeIn") + language },
    ];
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setFinalData(lines);
    setSteps(5);
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="container">
          <div className="main-layout blog-post-wrap">
            <section className="tool-panel">
              <section className="header-txt mt-3 text-center pb-0">
                <h2 className="text-center">{data?.tool_title}</h2>
                <p>{data?.tool_description}</p>
              </section>
              <div className="blog-steps-wrap mb-3 form-wizard card no_dark">
                <nav className="navbar navbar-expand navbar-light ">
                  <div className="form-wizard-steps clearfix navbar-nav">
                    <Link
                      role="button"
                      data-rr-ui-event-key="step1"
                      className={`nav-link ${steps >= 1 ? "activated" : ""}`}
                      tabIndex={0}
                      // href="#"
                      onClick={() => setSteps(1)}
                    >
                      <span>1</span>
                      <div>
                        {t("common.ideas")}
                      </div>
                    </Link>
                    <Link
                      role="button"
                      data-rr-ui-event-key="step2"
                      className={`nav-link ${steps >= 2 ? "activated" : ""} ${
                        steps < 2 ? "disabled" : ""
                      }`}
                      tabIndex={0}
                      onClick={() => setSteps(2)}
                    >
                      <span>2</span>
                      <div>
                        {t("common.title")}
                      </div>
                    </Link>
                    <Link
                      role="button"
                      data-rr-ui-event-key="step3"
                      className={`nav-link ${steps >= 3 ? "activated" : ""} ${
                        steps < 3 ? "disabled" : ""
                      }`}
                      aria-disabled="true"
                      onClick={() => setSteps(3)}
                    >
                      <span>3</span>
                      <div>
                        {t("common.intro")}
                      </div>
                    </Link>
                    <Link
                      role="button"
                      data-rr-ui-event-key="step4"
                      className={`nav-link ${steps >= 4 ? "activated" : ""} ${
                        steps < 4 ? "disabled" : ""
                      }`}
                      aria-disabled="true"
                      onClick={() => setSteps(4)}
                    >
                      <span>4</span>
                      <div>
                        {t("common.outline")}
                      </div>
                    </Link>
                    <Link
                      role="button"
                      data-rr-ui-event-key="step5"
                      // className={`nav-link ${steps >= 5 ?"activated":"disabled"}`}
                      className={`nav-link ${steps >= 5 ? "activated" : ""} ${
                        steps < 5 ? "disabled" : ""
                      }`}
                      aria-disabled="true"
                      onClick={() => setSteps(5)}
                    >
                      <span>5</span>
                      <div>
                        {t("common.editor")}
                      </div>
                    </Link>
                  </div>
                </nav>
              </div>
              <div className="row">
                <div className="ai-board col-md-12">
                  <div className="infoTooltip2">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15px"
                      height="15px"
                      x="0px"
                      y="0px"
                      viewBox="0 0 330 330"
                      enableBackground="new 0 0 330 330;"
                    >
                      <g>
                        <g>
                          <g>
                            <path
                              d="M165,0.008C74.019,0.008,0,74.024,0,164.999c0,90.977,74.019,164.992,165,164.992s165-74.015,165-164.992
            C330,74.024,255.981,0.008,165,0.008z M165,299.992c-74.439,0-135-60.557-135-134.992S90.561,30.008,165,30.008
            s135,60.557,135,134.991C300,239.436,239.439,299.992,165,299.992z"
                            />
                            <path
                              d="M165,130.008c-8.284,0-15,6.716-15,15v99.983c0,8.284,6.716,15,15,15s15-6.716,15-15v-99.983
            C180,136.725,173.284,130.008,165,130.008z"
                            />
                            <path
                              d="M165,70.011c-3.95,0-7.811,1.6-10.61,4.39c-2.79,2.79-4.39,6.66-4.39,10.61s1.6,7.81,4.39,10.61
            c2.79,2.79,6.66,4.39,10.61,4.39s7.81-1.6,10.609-4.39c2.79-2.8,4.391-6.66,4.391-10.61s-1.601-7.82-4.391-10.61
            C172.81,71.61,168.95,70.011,165,70.011z"
                            />
                          </g>
                        </g>
                      </g>{" "}
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                    </svg>
                    <span className="tooltiptext">
                      {t("common.automaticallyGeneratedContentUsingAI")}
                    </span>
                  </div>
                  {steps == 1 && (
                    <div className="card">
                      <div className="ai-board-header">
                        <div>
                          <h5 className="float-left">
                            {t("common.blogPostIdea")}
                          </h5>
                          <button
                            type="button"
                            className="desk d-block m-0 p-0 float-right haveTitle btn btn-link"
                            onClick={() => setSteps(2)}
                          >
                            <span>
                              {" "}
                              {t("common.alreadyHaveA")} <strong>{t("common.blogTitle")}</strong>? {t("common.clickHere")}..
                            </span>
                          </button>
                          <button
                            type="button"
                            className="mobi d-block m-0 p-0 float-right haveTitle btn btn-link"
                          >
                            <span>
                              {t("common.haveA")} <strong>{t("common.blogTitle")}</strong>?
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="ai-board-body card-body">
                        <div className="mb-3">
                          <label className="form-label">
                            {t("common.whatBlogPostToWrite")}
                          </label>{" "}
                          {/* <span className="float-right text-muted in-text">
                                        0/200
                                        </span> */}
                          <textarea
                            required=""
                            maxLength={200}
                            placeholder={
                              data?.data?.length > 0 &&
                              data?.data[0]?.placeholder
                            }
                            name="blogIdea"
                            className="form-control"
                            style={{ height: 64 }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                        <div className="row mobile_vvv">
                          <div className="mb-3 col-6">
                            <label className="form-label">
                              {t("common.keywordsOptional")}
                            </label>
                            <input
                              name="blogKeywords"
                              placeholder={
                                data?.data?.length > 0 &&
                                data?.data[1]?.placeholder
                              }
                              type="text"
                              className="form-control"
                              value={brandname}
                              onChange={(e) => setBrandName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label className="form-label">
                              {t("common.language")}
                            </label>
                            <select
                              name="language"
                              className="form-control form-select"
                              id="language"
                              value={language}
                              onChange={(e) => setLanguage(e.target.value)}
                            >
                              <option value="English (US)">{t("common.englishUS")}</option>
                              <option value="English (UK)">{t("common.englishUK")}</option>
                              <option value="French">{t("common.french")}</option>
                              <option value="Spanish">{t("common.spanish")}</option>
                              <option value="German">{t("common.german")}</option>
                              <option value="Italian">{t("common.italian")}</option>
                              <option value="Dutch">{t("common.dutch")}</option>
                              <option value="Arabic">{t("common.arabic")}</option>
                              <option value="Portuguese">{t("common.portuguese")}</option>
                              <option value="Portuguese (BR)">{t("common.portugueseBR")}</option>
                              <option value="Swedish">{t("common.swedish")}</option>
                              <option value="Norwegian">{t("common.norwegian")}</option>
                              <option value="Danish">{t("common.danish")}</option>
                              <option value="Finnish">{t("common.finnish")}</option>
                              <option value="Romanian">{t("common.romanian")}</option>
                              <option value="Czech">{t("common.czech")}</option>
                              <option value="Slovak">{t("common.slovak")}</option>
                              <option value="Slovenian">{t("common.slovenian")}</option>
                              <option value="Hungarian">{t("common.hungarian")}</option>
                              <option value="Hebrew">{t("common.hebrew")}</option>
                              <option value="Polish">{t("common.polish")}</option>
                              <option value="Turkish">{t("common.turkish")}</option>
                              <option value="Russian">{t("common.russian")}</option>
                              <option value="Hindi">{t("common.hindi")}</option>
                              <option value="Thai">{t("common.thai")}</option>
                              <option value="Japonese">{t("common.japonese")}</option>
                              <option value="Chinese (Simplified)">
                                  {t("common.chineseSimplified")}
                              </option>
                              <option value="Korean">{t("common.korean")}</option>
                              <option value="Indonesian">{t("common.indonesian")}</option>
                              <option value="Greek">{t("common.greek")}</option>
                              <option value="Bulgarian">{t("common.bulgarian")}</option>
                              <option value="Ukrainian">{t("comomon.ukrainian")}</option>
                              <option value="Estonian">{t("common.estonian")}</option>
                              <option value="Latvian">{t("common.latvian")}</option>
                              <option value="Lithuanian">{t("common.lithuanian")}</option>
                              <option value="Croatian">{t("common.croatian")}</option>
                              <option value="Serbian">{t("common.serbian")}</option>
                              <option value="Bosnian">{t("common.bosnian")}</option>
                              <option value="Macedonian">{t("common.macedonian")}</option>
                              <option value="Albanian">{t("common.albanian")}</option>
                              <option value="Vietnamese">{t("common.vietnamese")}</option>
                            </select>
                          </div>
                        </div>

                        <button
                          disabled={loading}
                          className="mt-3 mb-3 mr-auto ml-auto btn btn-primary"
                          onClick={() => do_make_action()}
                        >
                          {loading ? (
                            <div className="flex">
                              <span>
                                {t("common.loading")}...
                              </span>
                            </div>
                          ) : (
                            <span>{t("common.generateContent")}</span>
                          )}
                        </button>
                        <div />
                      </div>
                    </div>
                  )}
                  {steps == 2 && (
                    <div className="card">
                      <div className="ai-board-header">
                        <div>
                          <div className="float-left sec-form-head">
                            <h5>
                              {t("common.postTitle")}
                            </h5>
                            <span>
                              {t("common.selectATitleOrWriteYourOwn")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ai-board-body card-body">
                        <div className="ai-section height-align text-center">
                          <div className="float-left w-100 mb-4">
                            <label className="ai-content customBlogTitle row border-0 m-0">
                              <span style={{ margin: "10px 0px 10px -10px" }}>
                                {t("common.writeYourBlogTitle")}
                              </span>
                              <input
                                name="blogTitle"
                                placeholder={
                                  data?.data?.length > 0 &&
                                  data?.data[2]?.placeholder
                                }
                                type="text"
                                className="col-md-9 col-sm mb-3 form-control"
                                value={titleselected}
                                onChange={(e) =>
                                  setTitleSelected(e.target.value)
                                }
                              />
                              <div className="col-md-3 col-sm">
                                <button
                                  disabled={loading}
                                  type="button"
                                  value="Write your blog title.."
                                  className="btn btn-primary text-center d-block"
                                  onClick={() =>
                                    do_select_title(titleselected, 0)
                                  }
                                >
                                  {loading ? (
                                    <div className="flex">
                                      <span>
                                        {t("common.loading")}...
                                      </span>
                                    </div>
                                  ) : (
                                    <span>{t("common.generateContent")}</span>
                                  )}
                                </button>
                              </div>
                            </label>
                          </div>
                        </div>
                        {responseAI.length > 0 && (
                          <>
                            <p class="text-in">
                              {t("common.selectYourBlogTitle")}
                            </p>
                          </>
                        )}
                        {responseAI.map((v, i) => (
                          <div
                            className="ai-section height-align text-center"
                            key={"v_" + i}
                          >
                            <div className="ai-content flexbox">
                              <div
                                id={i}
                                className="cursor"
                                contenteditable="true"
                              >
                                {v}
                              </div>
                              <div className="selectBtn">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary float-right btn-sm"
                                  onClick={() => do_select_title(v, i)}
                                >
                                  Select
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        {responseAI.length > 0 && (
                          <button
                            disabled={loading}
                            className="btn btn-primary"
                            style={{ float: "left" }}
                            onClick={() => do_make_action(1)}
                          >
                            {loading ? (
                              <div className="flex">
                                <span>
                                {t("common.loading")}...
                                </span>
                              </div>
                            ) : (
                              <span>{t("common.generateContent")}</span>
                            )}
                          </button>
                        )}
                        <div>
                          <div />
                        </div>
                        <div />
                      </div>
                    </div>
                  )}
                  {steps == 3 && (
                    <div className="card">
                      <div className="ai-board-header">
                        <div>
                          <div className="float-left sec-form-head">
                            <h5>{titleselected}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="ai-board-body card-body">
                        <div className="ai-section height-align text-center">
                          <div className="float-left w-100 mb-4">
                            {introAI.length > 0 && (
                              <>
                                <p class="text-in text-left">
                                  {t("common.selectYourBlogIntro")}
                                </p>
                              </>
                            )}
                            {introAI.map((v, i) => (
                              <div
                                className="ai-section height-align text-center"
                                key={"vv_" + i}
                              >
                                <div className="ai-content flexbox">
                                  <div
                                    id={i}
                                    className="cursor"
                                    contenteditable="true"
                                  >
                                    {v}
                                  </div>
                                  <div className="selectBtn">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary float-right btn-sm"
                                      onClick={() => do_select_intro(v, i)}
                                    >
                                      {t("common.select")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {introAI.length > 0 && (
                              <button
                                disabled={loading}
                                className="btn btn-primary"
                                style={{ float: "left" }}
                                onClick={() => do_make_intro()}
                              >
                                {loading ? (
                                  <div className="flex">
                                    <span>
                                      {t("common.loading")}...
                                    </span>
                                  </div>
                                ) : (
                                  <span>{t("common.generateContent")}</span>
                                )}
                              </button>
                            )}
                            {introAI.length == 0 && (
                              <>
                                <div className="col-md-12 col-sm">
                                  <button
                                    disabled={loading}
                                    className="btn btn-primary text-center d-block"
                                    onClick={() => do_make_intro()}
                                  >
                                    {loading ? (
                                      <div className="flex">
                                        <span>
                                          {t("common.loading")}...
                                        </span>
                                      </div>
                                    ) : (
                                      <span>{t("common.generateContent")}</span>
                                    )}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div>
                          <div />
                        </div>
                        <div />
                      </div>
                    </div>
                  )}
                  {steps == 4 && (
                    <div className="card">
                      <div className="ai-board-header">
                        <div>
                          <div className="float-left sec-form-head">
                            <h5>{titleselected}</h5>
                            <span>
                              {t("common.generateTheBlogPostIntroduction")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ai-board-body card-body">
                        <div className="ai-section height-align text-center">
                          <div className="float-left w-100 mb-4">
                            {outlineAI.length > 0 && (
                              <>
                                <p class="text-in text-left">
                                  {t("common.selectYourBlogSectionHeadings")}
                                </p>
                              </>
                            )}
                            {outlineAI.map((v, i) => (
                              <div
                                className="ai-section height-align text-center"
                                key={"vv_" + i}
                              >
                                <div
                                  className="ai-content flexbox"
                                  style={
                                    selectedItems.includes(v)
                                      ? { border: "1px solid green" }
                                      : {}
                                  }
                                >
                                  <div
                                    id={i}
                                    className="cursor"
                                    contenteditable="true"
                                  >
                                    {v}
                                  </div>
                                  <div className="selectBtn">
                                    <div
                                      className="checkbox_icon"
                                      onClick={() =>
                                        do_save_heading_to_fetch(v)
                                      }
                                    >
                                      {selectedItems.includes(v) && (
                                        <div className="inner_checked"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {outlineAI.length > 0 && (
                              <button
                                disabled={loading}
                                className="btn btn-primary text-center d-block"
                                onClick={() => generate_full_content()}
                              >
                                {loading ? (
                                  <div className="flex">
                                    <span>
                                      {t("common.loading")}...
                                    </span>
                                  </div>
                                ) : (
                                  <span>{t("common.generateContent")}</span>
                                )}
                              </button>
                            )}
                            {outlineAI.length == 0 && (
                              <>
                                <div className="col-md-12 col-sm">
                                  <button
                                    disabled={loading}
                                    className="btn btn-primary text-center d-block"
                                    onClick={() => do_make_headings()}
                                  >
                                    {loading ? (
                                      <div className="flex">
                                        <span>
                                          {t("common.loading")}...
                                        </span>
                                      </div>
                                    ) : (
                                      <span>{t("common.generateContent")}</span>
                                    )}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div>
                          <div />
                        </div>
                        <div />
                      </div>
                    </div>
                  )}
                  {steps == 5 && (
                    <>
                      <div className="" style={{ paddingBottom: "10px" }}>
                        <div>
                          <div className="float-left sec-form-head">
                            <h5>
                              {t("common.title")}: <small></small>
                              {titleselected}
                            </h5>
                              {t("common.generateTheBlogPostIntroduction")}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="ai-board-body card-body">
                          <div className="ai-section height-align text-center">
                            <div className="float-left w-100 mb-4">
                              <div
                                dangerouslySetInnerHTML={{ __html: finalData }}
                                className="col-md-12 col-sm text-left custom_htmllll"
                              ></div>
                            </div>
                          </div>
                          <div>
                            <div />
                          </div>
                          <div />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Inner;

import React, { useEffect, useState } from "react";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import HEADER from "../../base-components/Header/header_login";
import { Translate } from "react-auto-translate";
import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../hooks/useAuth";
import { FaVk, FaYandex } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Signup() {
  const { setLoggedObject } = useAuth();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [dlang, setDlang] = useState("");
  const [privacycheck, setPrivacyCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [onFocus, setOnFoucs] = useState(false);
  const { t } = useTranslation();
  const getPasswordStrengthWidth = () => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasMinimumLength = password.length >= 6;

    if (hasMinimumLength && hasUpperCase && hasLowerCase) {
      return "100%";
    } else if (hasMinimumLength && (hasUpperCase || hasLowerCase)) {
      return "75%";
    } else if (password.length > 0) {
      return "25%";
    } else {
      return "0%";
    }
  };

  const getPasswordStrengthColor = () => {
    if (
      password.length >= 6 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      return "green";
    } else if (password.length >= 6 && /[a-zA-Z]/.test(password)) {
      return "orange";
    } else {
      return "red";
    }
  };

  const calculatePasswordStrength = (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasMinimumLength = value.length >= 6;
    if (hasMinimumLength && hasUpperCase && hasLowerCase) {
      setPasswordStrength(lang == "en" ? "Strong" : "Сильный");
    } else if (hasMinimumLength && (hasUpperCase || hasLowerCase)) {
      setPasswordStrength(lang == "en" ? "Moderate" : "Умеренный");
    } else if (value.length > 0) {
      setPasswordStrength(lang == "en" ? "Weak" : "Слабый");
    } else {
      setPasswordStrength("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    calculatePasswordStrength(value);
  };

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    setDlang(lang);
  }, []);

  const [SignupSuccess, setSignupSuccess] = useState(false);

  const do_signup = async () => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasMinimumLength = password.length >= 6;

    if (name === "") {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.enter_full_name?.notification
          : "Пожалуйста введите свое полное имя!"
      );
      return;
    }

    if (email === "") {
      toast.error(
        lang == "en"
          ? "Please enter your email address!"
          : "Пожалуйста, введите Ваш адрес электронной почты!"
      );
      return;
    }
    if (!validateEmail(email)) {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.enter_valid_email?.notification
          : "Пожалуйста, введите действительный адрес электронной почты!"
      );
      return false;
    }
    if (password === "") {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.enter_password?.notification
          : "Пожалуйста введите ваш пароль!"
      );
      return;
    }
    if (cpassword === "") {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.enter_confirm_password?.notification
          : "Пожалуйста, введите подтверждение пароля!"
      );
      return;
    }
    if (!hasUpperCase || !hasLowerCase || !hasMinimumLength) {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.password_strength?.notification
          : "Пароль должен состоять из 6 или более символов латинского алфавита, содержать заглавные и строчные буквы"
      );
      return;
    }
    if (cpassword !== password) {
      toast.error(
        lang == "en"
          ? NotificationsToasts?.pass_confirm_password_not_matching?.notification
          : "Пароль и подтверждение пароля не совпадают!"
      );
      return;
    }

    const payload = {
      fullname: name,
      email: email,
      password: password,
      cpassword: cpassword,
      lang: dlang,
    };
    // console.log(payload);
    setLoading(true);
    setSignupSuccess(false);
    const { isError, data } = await doPost(payload, "auth/user_signup");
    // console.log(data);
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setSignupSuccess(true);
        // toast.success("Your account has been created successfully!");
        // navigate("/login");
      } else {
        setLoading(false);
        setSignupSuccess(false);
        toast.error(data?.data);
      }
    }
  };

  // Signup ......

  const do_google_login = async (name, emailg, pic, id) => {
    const payload = {
      email: emailg,
      password: id,
      fullname: name,
      pic: pic,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_signup_google");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        // window.location.reload();
        // navigate("/dashboard")
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const google_login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenResponse?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          do_google_login(data?.name, data?.email, data?.picture, data?.sub);
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const do_redirect_yandex_login = () => {
    // window.location.href = 'https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=http://localhost:3000/login&force_confirm=yes';
    // window.location.href = 'https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=https://wkraft.dedevelopers.org/login&force_confirm=yes';
    window.location.href =
      "https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=https://aiword.online/login&force_confirm=yes";
  };

  const do_redirect_VK_login = () => {
    window.location.href =
      "https://oauth.vk.com/authorize?client_id=51822500&display=page&redirect_uri=https://e90c-119-155-24-49.ngrok-free.app/login&scope=friends&response_type=token&v=5.131&state=123456";
  };

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    if (currentUrl.includes("?code=")) {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code != "") {
        do_yande_check_login(code);
      }
      console.log("Code:", code);
    }
  }, []);

  const do_yande_check_login = async (code) => {
    const payload = {
      code: code,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_confirm_yandex");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error_description);
      }
    }
  };
  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("#access_token=")) {
      const token = new URLSearchParams(window.location.hash.substring(1)).get(
        "access_token"
      );
      if (token != null) {
        do_vk_check_login(token);
      }
    } else {
      console.log("No code");
    }
  }, []);

  const do_vk_check_login = async (token) => {
    const payload = {
      token: token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_confirm_vk");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error_description);
      }
    }
  };

  return (
    <div className="layout-container container-fluid">
      <HEADER />
      <div className="layout-row row">
        <div className="scrollable-body noSidebar col-md-12 col-12">
          <div className="container-wrappe">
            <div className="col-4 p-0 card my-5 mx-auto loginForm card">
              <div className="card-body">
                <div className="p-4 box">
                  <h2>
                    {t("common.LetsGetStarted")}
                  </h2>
                  <p>
                    {t("common.signUpToMakeYourContent")}
                  </p>
                  {/* <form className> */}
                  <div className="mb-3">
                    <input
                      placeholder={t("common.fullName")}
                      required
                      type="name"
                      id="formBasicName"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      placeholder={t("common.emailAddress")}
                      required
                      type="email"
                      id="formBasicEmail"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div style={{ position: "relative" }} className="mb-3">
                    <input
                      placeholder={t("common.password")}
                      required
                      type="password"
                      className="form-control"
                      value={password}
                      onFocus={() => setOnFoucs(true)}
                      onChange={handlePasswordChange}
                    />
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          width: getPasswordStrengthWidth(),
                          borderBottom: "2px solid",
                          borderColor: getPasswordStrengthColor(),
                        }}
                      />
                      <div className="Password_length">
                        {onFocus && (
                          <>
                            <p>{passwordStrength}</p>
                            {lang == "en" ? (
                              <p>
                                The password must consist of 6 or more
                                characters of the Latin alphabet, contain
                                uppercase and lowercase letters.
                              </p>
                            ) : (
                              <p>
                                Пароль должен состоять из 6 или более символов
                                латинского алфавита, содержать заглавные и
                                строчные буквы.
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <input
                      placeholder={t("common.confirmPassword")}
                      required
                      type="password"
                      id="formBasicConfirmPassword"
                      className="form-control"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                  </div>

                  <div>
                    <div className="acceptance_text">
                      {lang == "en" ? (
                        <span>
                          By registering, you agree to the terms of the Digital
                          Data{" "}
                          <Link to={"https://aiword.ru/policy"}>
                            Processing Policy
                          </Link>{" "}
                          and the{" "}
                          <Link to={"https://aiword.ru/contract"}>
                            Public Offer
                          </Link>{" "}
                          .
                        </span>
                      ) : (
                        <span>
                          Регистрируясь, вы соглашаетесь с условиями Политики
                          обработки{" "}
                          <Link to={"https://aiword.ru/policy"}>
                            цифровых данных
                          </Link>{" "}
                          и &nbsp;
                          <Link to={"https://aiword.ru/contract"}>
                            Публичной офертой.
                          </Link>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-grid gap-2">
                    <button
                      disabled={loading}
                      type="Submit"
                      className="btn btn-primary"
                      onClick={() => do_signup()}
                    >
                      <span>
                        {loading ? t("common.submitting") : t("common.signUp")}
                      </span>
                    </button>
                  </div>
                  <div className="termsofService">
                    {t("common.bySigningUpIAgree")}
                  </div>
                  {/* </form> */}
                </div>
                <div className="relative">
                  <hr />
                  <div className="or_text">
                    {t("common.or")}
                  </div>
                </div>
                <div>
                  <button
                    style={{
                      height: "40px",
                      backgroundColor: "#ED1E23",
                      marginTop: "10px",
                      color: "white",
                      border: "none",
                    }}
                    disabled={loading}
                    type="button"
                    className="w-100 googleSignin btn"
                    onClick={() => (loading ? "" : do_redirect_yandex_login())}
                  >
                    {loading ? (
                      <div className="flex p-2">
                        <span>
                          {t("common.pleaseWait")}...
                        </span>
                      </div>
                    ) : (
                      <>
                        <FaYandex className="SinupColorBtn" />
                        {t("common.signupWithYandex")}
                      </>
                    )}
                  </button>

                  <button
                    style={{
                      height: "40px",
                      marginTop: "10px",
                      backgroundColor: "white",
                    }}
                    disabled={loading}
                    type="button"
                    className="w-100 googleSignin btn"
                    onClick={() => (loading ? "" : google_login())}
                  >
                    {loading ? (
                      <div className="flex p-2">
                        <span>
                          {t("common.pleaseWait")}...
                        </span>
                      </div>
                    ) : (
                      <>
                        <FcGoogle className="SinupColorBtn" />
                        {t("common.signupWithGoogle")}
                      </>
                    )}
                  </button>
                </div>
                <div className="p-4 box text-center">
                  <span>{t("common.alreadyHaveAnAccount")}</span>{" "}
                  <Link to="/login">
                    {t("common.signIn")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {SignupSuccess && (
        <>
          <div className="outer_image">
            <div className="inner_image">
              <div className="box_inner_image">
                <p style={{ marginBottom: "0px" }}>
                  <p>
                    {t("common.thankYouForRegistering")} ({name})! <br /> <br /> 
                    {t("common.aConfirmationEmailHasBeenSent")}{" "}
                    {email}. <br /> <br /> {t("common.openThisEmailAndClick")}
                  </p>
                  <button
                    disabled={loading}
                    type="Submit"
                    className="btn btn-primary"
                    onClick={() => navigate("/login")}
                  >
                    {t("common.goToLogin")}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
}

export default Signup;

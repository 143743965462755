import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO_BLUE from "../../assets/images/logo_blue.svg";
import LOGINIMG from "../../assets/images/login_img.svg";
import BOOKMARK from "../../assets/images/bookmark2.svg";
import LOADING from "../../assets/images/loading.gif";
import { Translate } from "react-auto-translate/lib/commonjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function Verify() {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    handle_verify();
  }, []);

  const handle_verify = async () => {
    const payload = {
      verify_code: slugFromUrl,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_check_verify");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        const logged = await checkLogin();
        navigate("/login");
        // if(logged){
        //     setLoggedObject(data?.data);
        // }
        toast.success(NotificationsToasts?.account_verified_successfully?.notification);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container_s">
      <style>{`body { background-color: #fff !important; }`}</style>
      <div className="account_verify">
        {loading ? (
          <div className="form_section_login ">
            <div className="wow animate__heartBeat">
              {t("common.loading")}...
            </div>
          </div>
        ) : (
          <div className="form_section_login">
            <div className="form-group">
              <p>
                {t("common.yourAccountHasBeenSuccessfullyConfirmed")}
              </p>
            </div>
            <div className="form-group mt-20px">
              <button
                className="btn btn-primary wd100"
                onClick={() => navigate("/login")}
              >
                {t("common.continue")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Verify;

import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OPENAPI_IMAGE } from "./../../utils/apiCalls";
import { Translate } from 'react-auto-translate';
import ResponseDiv from './Response';
import ImageResponse from "./imageResponse";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Inner({ data = [] }) {
    const { state } = useLocation();
    const [titleab, setTitleab] = useState(state?.v?.title);
    const { NotificationsToasts = null } = useSelector((state) => state.user);

    const [descab, setDesceab] = useState(state?.v.description);
    const [blogimage, setBlogImage] = useState(state?.v.image);

    const [option, setOption] = useState(false);
    const { getLoggedObject } = useAuth();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [description, setDescription] = useState('');

    const [variant, setVariant] = useState(1);
    const [imgsize, setImgSize] = useState('square');

    const [classify, setClassify] = useState('');
    const [style, setStyle] = useState('');
    const [artist, setArtist] = useState('');
    const [mood, setMood] = useState('');

    const [creativity, setCreativity] = useState('0.75');
    const [responseAI, setResponseAI] = useState([]);
    const [type, setType] = useState('flexy_templates');


    useEffect(() => {
        const [width, height] = imgsize.split('x');
        setImgHeight(height);
        setImgWidth(width);
    }, [imgsize]);

    const [imgHeight, setImgHeight] = useState('');
    const [imgWidth, setImgWidth] = useState('');

    const do_loop_variants = async () => {

        let lang = localStorage.getItem("AIWORDLANG");
        setLoading(true)
        const user_token = getLoggedObject()?.token;
        const response_ai = await OPENAPI_IMAGE(description, user_token, style, variant, imgWidth, mood, NotificationsToasts);
        setLoading(false)
        if (response_ai == 'error') {
            toast.error(NotificationsToasts?.error_while_connecting?.notification);
            return;
        }
        if (response_ai == 'ntoken') {
            toast.error(NotificationsToasts?.account_limit_reached?.notification);
            return;
        }


        const data = await response_ai;
        setResponseAI(data.data.images);
    }

    const do_make_action = async () => {
        if (description == '') {
            toast.error(NotificationsToasts?.image_description?.notification);
            return;
        }
        do_loop_variants();
    }

    return (
        <>
            <div className="container-wrappe">
                <div className="main-layout">
                    <section className="tool-panel">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <section className="tool-header">
                                            <div>
                                                <div className="svgIcon imgCreator">
                                                    <img src={blogimage} />
                                                </div>
                                                <div>
                                                    <h2>{data?.tool_title}</h2>
                                                    <p>{data?.tool_description}</p>
                                                </div>
                                            </div>

                                        </section>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="imageDescription">
                                                {t("common.writeAnImageDescription")}
                                            </label>{" "}
                                            {/* <span className="float-right text-muted in-text">
                            0/500 characters
                        </span> */}
                                            <textarea
                                                maxLength={500}
                                                placeholder={
                                                    data?.data?.length > 0 &&
                                                    data?.data[1]?.placeholder
                                                }
                                                name="imageDescription"
                                                required=""
                                                id="imageDescription"
                                                className="form-control"
                                                style={{ height: 120 }}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                        <div className="mt-3 mb-3 row">
                                            <div className="col-6 mb-3 col">
                                                <label className="form-label">{t("common.imageSize")}</label>
                                                <select
                                                    aria-label="Default select example"
                                                    name="imageSize"
                                                    className="form-control form-select"
                                                    value={imgsize}
                                                    onChange={(e) => setImgSize(e.target.value)}
                                                >
                                                    <option value="square">Square</option>
                                                    <option value="landscape">Landscape</option>
                                                    <option value="smallPortrait">Small Portrait</option>
                                                    <option value="portrait">Portrait</option>
                                                    <option value="wide">Wide</option>
                                                </select>
                                            </div>
                                            <div className="col-6 mb-3 col">
                                                <label className="form-label">{t("common.variants")}</label>
                                                <select
                                                    aria-label="Number of Variants"
                                                    name="imageVariant"
                                                    className="form-control form-select"
                                                    value={variant}
                                                    onChange={(e) => setVariant(e.target.value)}
                                                >
                                                    <option value={1}>1 {t("common.variant")}</option>
                                                    <option value={2}>2 {t("common.variants")}</option>
                                                    <option value={3}>3 {t("common.variants")}</option>
                                                    <option value={4}>4 {t("common.variants")}</option>
                                                </select>
                                            </div>
                                            {/* <div className="col-12 mb-3 col">
                                                <label className="form-label">{t("common.mood")}</label>
                                                <select
                                                    name="imageMood"
                                                    className="form-control form-select"
                                                    id="imageMood"
                                                    value={mood}
                                                    onChange={(e) => setMood(e.target.value)}
                                                >
                                                    <option value="color">Color</option>
                                                    <option value="structure">Structure</option>
                                                    <option value="depth">Depth</option>
                                                    <option value="scribble">Scribble</option>
                                                </select>
                                            </div> */}
                                        </div>
                                        <div className="advanceOptions">
                        <div className="accordion">
                            <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                type="button"
                                aria-expanded="false"
                                className="accordion-button collapsed"
                                onClick={()=>setOption(!option)}
                                >
                                    {t("common.advancedOptions")}
                                </button>
                            </h2>
                            {
                                option &&
                           
                            <div className="accordion-collapse collaps">
                                <div className="accordion-body">
                                <div className="mt-3 mb-3 row">
                                    {/* <div className="col-6 mb-3 col">
                                    <label className="form-label">{t("common.classify")}</label>
                                    <select
                                        name="imageType"
                                        className="form-control form-select"
                                        id="imageType"
                                        value={classify}
                                        onChange={(e)=>setClassify(e.target.value)}
                                    >
                                        <option value="none">{t("common.none")}</option>
                                        <option value="Realistic">{t("common.realistic")}</option>
                                        <option value="Fictional">{t("common.fictional")}</option>
                                    </select>
                                    </div> */}
                                    <div className="col-12 mb-3 col">
                                    <label className="form-label">{t("common.style")}</label>
                                    <select
                                        name="imageStyle"
                                        className="form-control form-select"
                                        id="imageStyle"
                                        value={style}
                                        onChange={(e)=>setStyle(e.target.value)}
                                    >
                                        <option value="lyra">{t("common.lyra")}</option>
                                        <option value="hydra">{t("common.hydra")}</option>
                                        <option value="fantasy">{t("common.fantasy")}</option>
                                        <option value="portrait">{t("common.portrait")}</option>
                                        <option value="inpunk">{t("common.inpunk")}</option>
                                        <option value="abstractWorld">{t("common.abstractWorld")}</option>
                                        <option value="anime">{t("common.anime")}</option>
                                        <option value="argo">{t("common.argo")}</option>
                                        <option value="cinematic">{t("common.cinematic")}</option>
                                        <option value="photography">{t("common.photography")}</option>
                                        <option value="scifi">{t("common.scifi")}</option>
                                        <option value="detailedIllustration">{t("common.detailedIllustration")}</option>
                                        <option value="3dIllustration">{t("common.3DIllustration")}</option>
                                        <option value="flatIllustration">{t("common.flatIllustration")}</option>
                                        <option value="realvisxl">{t("common.realvisxl")}</option>
                                        <option value="stylevisionxl">{t("common.stylevisionxl")}</option>
                                        <option value="animaginexl">{t("common.animaginexl")}</option>
                                        <option value="anime_2">{t("common.anime")} 2</option>
                                        <option value="anime_stylized">{t("common.animeStylized")}</option>
                                        <option value="anime_vintage">{t("common.animeVintage")}</option>
                                        <option value="pixelart">{t("common.pixelArt")}</option>
                                        {/* <option value="none">{t("common.none")}</option>
                                        <option value="3d render">{t("common.3DRender")}</option>
                                        <option value="abstract">{t("common.abstract")}</option>
                                        <option value="anime">{t("common.anime")}</option>
                                        <option value="art deco">{t("common.artDeco")}</option>
                                        <option value="cartoon">{t("common.cartoon")}</option>
                                        <option value="digital art">{t("common.digitalArt")}</option>
                                        <option value="illustration">{t("common.illustration")}</option>
                                        <option value="line art">{t("common.lineArt")}</option>
                                        <option value="one line drawing">{t("common.oneLineDrawing")}</option>
                                        <option value="origami">{t("common.origami")}</option>
                                        <option value="pixel art">{t("common.pixelArt")}</option>
                                        <option value="photography">{t("common.photography")}</option>
                                        <option value="pop art">{t("common.popArt")}</option>
                                        <option value="retro">{t("common.retro")}</option>
                                        <option value="unreal engine">{t("common.unrealEngine")}</option>
                                        <option value="vaporwave">{t("common.vaporwave")}</option> */}
                                    </select>
                                    </div>
                                    <div className="col-12 mb-3 col">
                                    <label className="form-label">{t("common.mood")}</label>
                                        <select
                                            name="imageMood"
                                            className="form-control form-select"
                                            id="imageMood"
                                            value={mood}
                                            onChange={(e) => setMood(e.target.value)}
                                        >
                                            <option value="color">{t("common.color")}</option>
                                            <option value="structure">{t("common.structure")}</option>
                                            <option value="depth">{t("common.depth")}</option>
                                            <option value="scribble">{t("common.scribble")}</option>
                                        </select>
                                    </div>
                                    {/* <div className="col-6 mb-3 col">
                                    <label className="form-label">{t("common.artist")}</label>
                                    <select
                                        name="imageArtist"
                                        className="form-control form-select"
                                        id="imageArtist"
                                        value={artist}
                                        onChange={(e)=>setArtist(e.target.value)}
                                    >
                                        <option value="none">{t("common.none")}</option>
                                        <option value="andy warhol">{t("common.AndyWarhol")}</option>
                                        <option value="ansel adams">{t("common.AnselAdams")}</option>
                                        <option value="claude monet">{t("common.ClaudeMonet")}</option>
                                        <option value="dr. seuss">{t("common.DrSeuss")}</option>
                                        <option value="pablo picasso">{t("common.PabloPicasso")}</option>
                                        <option value="pixar">{t("common.Pixar")}</option>
                                        <option value="salvador dali">{t("common.SalvadorDali")}</option>
                                        <option value="south park">{t("common.SouthPark")}</option>
                                        <option value="van gogh">{t("common.VanGogh")}</option>
                                    </select>
                                    </div> */}
                                    {/* <div className="col-12 mb-3 col">
                                    <label className="form-label">{t("common.mood")}</label>
                                    <select
                                        name="imageMood"
                                        className="form-control form-select"
                                        id="imageMood"
                                        value={mood}
                                        onChange={(e)=>setMood(e.target.value)}
                                    >
                                        <option value="none">{t("common.none")}</option>
                                        <option value="aggressive">{t("common.aggressive")}</option>
                                        <option value="angry">{t("common.angry")}</option>
                                        <option value="boring">{t("common.boring")}</option>
                                        <option value="bright">{t("common.bright")}</option>
                                        <option value="calm">{t("common.calm")}</option>
                                        <option value="cheerful">{t("common.cheerful")}</option>
                                        <option value="chilling">{t("common.chilling")}</option>
                                        <option value="colorful">{t("common.colorful")}</option>
                                        <option value="dark">{t("common.dark")}</option>
                                        <option value="neutral">{t("common.neutral")}</option>
                                    </select>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                             }
                            </div>
                        </div>
                        </div>

                                        <button disabled={loading} className="mt-3 w-100 btn btn-primary" onClick={() => do_make_action()}>
                                            {loading ?
                                                <div className="flex">
                                                    <span> {t("common.loading")}...</span>
                                                </div>
                                                :
                                                <span>{t("common.generateImage")}</span>
                                            }
                                        </button>
                                        {/* <button type="button" className="exampleData m-auto btn btn-link">
                        Add example data
                    </button> */}
                                    </div>
                                </div>

                                <small className="imageNote">
                                    <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15px"
                                        height="15px"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 330 330"
                                        enableBackground="new 0 0 330 330;"
                                    >
                                        <g>
                                            <g>
                                                <g>
                                                    <path
                                                        d="M165,0.008C74.019,0.008,0,74.024,0,164.999c0,90.977,74.019,164.992,165,164.992s165-74.015,165-164.992
        C330,74.024,255.981,0.008,165,0.008z M165,299.992c-74.439,0-135-60.557-135-134.992S90.561,30.008,165,30.008
        s135,60.557,135,134.991C300,239.436,239.439,299.992,165,299.992z"
                                                    />
                                                    <path
                                                        d="M165,130.008c-8.284,0-15,6.716-15,15v99.983c0,8.284,6.716,15,15,15s15-6.716,15-15v-99.983
        C180,136.725,173.284,130.008,165,130.008z"
                                                    />
                                                    <path
                                                        d="M165,70.011c-3.95,0-7.811,1.6-10.61,4.39c-2.79,2.79-4.39,6.66-4.39,10.61s1.6,7.81,4.39,10.61
        c2.79,2.79,6.66,4.39,10.61,4.39s7.81-1.6,10.609-4.39c2.79-2.8,4.391-6.66,4.391-10.61s-1.601-7.82-4.391-10.61
        C172.81,71.61,168.95,70.011,165,70.011z"
                                                    />
                                                </g>
                                            </g>
                                        </g>{" "}
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                        <g />
                                    </svg>{" "}
                                    {t("common.noteTool150Word")}
                                </small>
                            </div>
                            <div className="ai-board col-md-8 col">
                                {/* {
                        responseAI.map(image => {
                            return (
                                <img src={image.url} />
                            )
                        })
                    } */}
                                <ImageResponse loading={loading} responseAI={responseAI} contentCategory={type} image_data={1} />
                            </div>
                        </div>
                    </section>
                </div>
                <div
                    style={{
                        position: "fixed",
                        zIndex: 9999,
                        inset: 16,
                        pointerEvents: "none"
                    }}
                />
            </div>



        </>
    );
}

export default Inner;

import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HEADER from "../../base-components/Header/header_login";
import { Translator, Translate } from "react-auto-translate";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function Login() {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);


  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const [dlang, setDlang] = useState("");

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    setDlang(lang);
  }, []);

  const do_action = async () => {
    if (email == "") {
      toast.error(NotificationsToasts?.enter_email?.notification);
      return;
    }
    if (!validateEmail(email)) {
      toast.error(NotificationsToasts?.enter_valid_email?.notification);
      return false;
    }
    const payload = {
      email: email,
      lang: dlang,
    };
    // console.log(payload);
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/send_forgot_otp");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success(NotificationsToasts?.otp_sent?.notification);
        navigate("/otp", { state: { user_email: email } });
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  return (
    <div className="layout-container container-fluid">
      <HEADER />
      <div className="layout-row row">
        <div className="scrollable-body noSidebar col-md-12 col-12">
          <div className="container-wrappe">
            <div className="col-4 p-0 card my-5 mx-auto loginForm card">
              <div className="card-body">
                <div className="p-4 box">
                  <h2>
                    {t("common.resetYourPassword")}
                  </h2>
                  <p>
                    <span>{t("common.emailToResetYourPassword")}</span>
                  </p>
                  {/* <form className> */}
                  <div className="mb-4">
                    <input
                      placeholder="Email address"
                      type="email"
                      id="formBasicEmail"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      type="Submit"
                      className="btn btn-primary"
                      onClick={() => do_action()}
                    >
                      {t("common.submit")}
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                <div className="p-4 text-center">
                  <p>
                    <Link to="/login">
                      {t("common.backToSignIn")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import { toast } from "react-toastify";
import { urls } from "./Api_urls";
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: "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",
  // apiKey: 'shds-HkOzMVIpITpZvIyekp7XcOiYqFK',
  dangerouslyAllowBrowser: true,
});

export async function doPost(body_data, url_plus) {
  // console.log(" I request @ " + urls.API + url_plus);
  // console.log(body_data);
  const { isError, data } = await fetch(urls.API + url_plus, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body_data),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return { isError: false, data: responseJson };
    })
    .catch((error) => {
      // console.log(error);
      return { isError: true, data: error };
    });
  return { isError, data };
}

export async function OPENAPI(body_data, maxts, temp, user_token) {
  const final_data = {
    messages: body_data,
    model: "gpt-4-turbo",
    // max_tokens: maxts,
    temperature: parseFloat(temp),
  };

  // CHECK IF USER REACHED LIMIT OF TOKENS
  const user_ay = {
    token: user_token,
  };
  const { isError, data } = await doPost(
    user_ay,
    "auth/check_user_total_tokens"
  );
  if (isError) {
    return "error";
  } else {
    if (data.action == "error") {
      return "ntoken";
    }
  }
  try {
    // const chatCompletion = await openai.chat.completions.create(final_data);
    const chatCompletion = await fetch(
      "https://gptunnel.ru/v1/chat/completions",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_TUNNEL_API_KEY || "",
        },
        body: JSON.stringify(final_data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return "error";
      });

    // await do_update_token(chatCompletion.usage.total_tokens, user_token)
    return chatCompletion;
  } catch (error) {
    return "error";
  }
}

export const do_update_token = async (
  token,
  user_token,
  NotificationsToasts
) => {
  // UPDATE USER TOKENS
  const payload_inner = {
    used_tokens: token,
    token: user_token,
  };
  const { isError, data } = await doPost(
    payload_inner,
    "auth/do_update_user_token"
  );
  if (isError) {
    toast.error(NotificationsToasts?.server_error?.notification);
    return;
  } else {
    if (data.action == "success") {
      localStorage.setItem("AIWORDAUTH", JSON.stringify(data?.data));
      return data?.data?.usage;

      // setLoggedObject(data?.data);
    } else {
      toast.error(data?.error);
      return;
    }
  }
};

// export async function OPENAPI_IMAGE(
//   body_data,
//   maxts,
//   temp,
//   user_token,
//   variant,
//   imgWidth,
//   imgHeight,
//   NotificationsToasts
// ) {
//   const user_ay = {
//     token: user_token,
//   };
//   const { isError, data } = await doPost(
//     user_ay,
//     "auth/check_user_total_tokens"
//   );
//   if (isError) {
//     return "error";
//   } else {
//     if (data.action == "error") {
//       return "ntoken";
//     }
//   }

//   const engineId = "stable-diffusion-v1-6";
//   const apiHost = "https://api.stability.ai";
//   const apiKey = "sk-DY6Y8lzVy2VEWn1iwA8StUlVr58SggYMfWFbAsGdwRmZntm2";

//   if (!apiKey) {
//     toast.error(NotificationsToasts?.missing_stability_api_key?.notification);
//   }

//   try {
//     const response = await fetch(
//       `${apiHost}/v1/generation/${engineId}/text-to-image`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${apiKey}`,
//         },
//         body: JSON.stringify({
//           text_prompts: [
//             {
//               text: body_data,
//             },
//           ],
//           cfg_scale: 7,
//           height: Number(imgHeight),
//           width: Number(imgWidth),
//           steps: 30,
//           samples: 1,
//         }),
//       }
//     );

//     // UPDATE USER TOKENS
//     const payload_inner = {
//       used_tokens: 150,
//       token: user_token,
//     };
//     const { isError, data } = await doPost(
//       payload_inner,
//       "auth/do_update_user_token"
//     );
//     if (isError) {
//       toast.error(NotificationsToasts?.server_error?.notification);
//       return;
//     } else {
//       if (data.action == "success") {
//         localStorage.setItem("AIWORDAUTH", JSON.stringify(data?.data));
//         // setLoggedObject(data?.data);
//       } else {
//         toast.error(data?.error);
//         return;
//       }
//     }
//     return response;
//   } catch (error) {
//     return "error";
//   }
// }

export async function OPENAPI_IMAGE(
  body_data,
  user_token,
  style,
  variant,
  imgWidth,
  mood,
  NotificationsToasts
) {
  try {
    const requestData = {
      body_data: body_data || '',
      style: style || 'lyra',
      imgWidth: imgWidth || 'square',
      variant: variant || 1,
      mood: mood || 'color',
      user_token: user_token
    };

    const postOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user_token}`,
      },
      body: JSON.stringify(requestData)
    };

    const postResponse = await fetch(`${urls.API}auth/proxy_image_generation`, postOptions);

    if (!postResponse.ok) {
      console.error("Error in POST request:", postResponse.statusText);
      NotificationsToasts?.server_error?.notification && toast.error(NotificationsToasts.server_error.notification);
      return "error";
    }

    const postResponseText = await postResponse.text();

    let postResult;
    try {
      postResult = JSON.parse(postResponseText);
    } catch (jsonError) {
      console.error("Failed to parse JSON:", jsonError);
      NotificationsToasts?.server_error?.notification && toast.error("Failed to parse server response.");
      return "error";
    }

    if (postResult.error) {
      console.error("Backend Error:", postResult.error);
      NotificationsToasts?.server_error?.notification && toast.error(postResult.error);
      return "error";
    }

    console.log("Image generated successfully:", postResult);
    return postResult;

  } catch (error) {
    console.error("Error in OPENAPI_IMAGE:", error);
    NotificationsToasts?.server_error?.notification && toast.error(NotificationsToasts.server_error.notification);
    return "error";
  }
}


export function CountWords(str) {
  const wordsArray = str.split(/\s+/);
  return wordsArray.length;
}

export function handleCopyClick(textToCopy, NotificationsToasts) {
  if (textToCopy) {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success(NotificationsToasts?.copied_clipboard?.notification);
    // setIsCopied(true);
  }
}

export async function handleSaveContent(
  val,
  type,
  words,
  token,
  NotificationsToasts
) {
  const payload = {
    words: words,
    token: token,
    type: type,
    val: val,
  };

  const { isError, data } = await doPost(payload, "auth/do_add_user_content");
  if (isError) {
    toast.error(NotificationsToasts?.server_error?.notification);
    return;
  } else {
    if (data.action == "success") {
      toast.success(NotificationsToasts?.content_saved?.notification);
    } else {
      toast.error(data?.error);
      return;
    }
  }
}

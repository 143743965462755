import React, { useState } from "react";
import { Translate } from "react-auto-translate";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ marginTop: "10px" }} className="accordion-item AccHeader">
      <h2 className="accordion-header">
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleOpen}
        >
          <span>{question}</span>
        </button>
      </h2>
      {isOpen && (
        <div className="accordion-collapse collapse show">
          <div
            dangerouslySetInnerHTML={{ __html: answer }}
            className="accordion-body"
          />
        </div>
      )}
    </div>
  );
};

export default FAQItem;

import React, { useEffect, useState } from "react";
import NavBarLOGIN from "../../base-components/NavBar/navbar_login";
import SideBar from "../../base-components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { doPost } from "../../utils/apiCalls";
import { Translate } from "react-auto-translate";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [allNotification, setNotification] = useState([]);
  const dispatch = useDispatch();
  const { getLoggedObject } = useAuth();
  const { t } = useTranslation();
  const getNotifications = async () => {
    const payload = {
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/getUserNotifications"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message === "success") {
        console.log(data);
        setNotification(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="layout-container container-fluid">
      <NavBarLOGIN />
      <div className="layout-row row">
        <SideBar />

        <div className="scrollable-body col-md-9 col-12">
          <h2>
            {t("common.notifications")}
          </h2>
          {loading ? (
            <div>
              {t("common.loading")}...
            </div>
          ) : (
            <ul className="list-group">
              {allNotification.map((note, index) => (
                <li
                  key={index}
                  className="border-0"
                  style={{ padding: "0", listStyle: "none" }}
                >
                  <div className="p-3 d-flex justify-content-between align-items-center custom_p_bot_zero">
                    <div className="" style={{ maxWidth: "90%" }}>
                      <span
                        dangerouslySetInnerHTML={{ __html: note.message }}
                      />
                    </div>
                    <span style={{ fontSize: "12px", color: "grey" }}>
                      {note.created_at}
                    </span>
                  </div>
                </li>
              ))}
              {allNotification.length === 0 && (
                <li className="list-group-item border-0">
                  {t("common.noNotificationsFound")}
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;

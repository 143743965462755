// importing local storage
import { useState, useCallback } from "react";

// local storage function that retreives the data
export async function retrieveItem(key) {
  try {
    const retrievedItem = localStorage.getItem(key);
    const item = JSON.parse(retrievedItem);
    return item;
  } catch (error) {}
  return;
}

// store data in lcoalstorage
export async function storeItem(key, item) {
  try {
    var jsonOfItem = localStorage.setItem(key, JSON.stringify(item));
    return jsonOfItem;
  } catch (error) {}
}

//validing email
export function validateEmail(text) {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
}

export function formatDate(dateObj) {
  var month = dateObj.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
    if (dateObj.getDate() < 10) {
      const dat = "0" + dateObj.getDate();
      let date = dateObj.getFullYear() + "-" + month + "-" + dat;
      return date;
    } else {
      let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate();
      return date;
    }
  } else {
    if (dateObj.getDate() < 10) {
      const dat = "0" + dateObj.getDate();
      let date = dateObj.getFullYear() + "-" + month + "-" + dat;
      return date;
    } else {
      let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate();
      return date;
    }
  }
}
export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text; 
  }

  const words = text.split(' ');

  let truncatedText = '';
  let currentLength = 0;

  for (const word of words) {
    if (currentLength + word.length > maxLength) {
      break;
    }

    truncatedText += word + ' ';

    currentLength += word.length + 1; 
  }

  truncatedText += '...';

  return truncatedText;
}

export function translateDate(dateString, locale) {
  const date = new Date(dateString);

  // Get the localized month name
  const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' });
  let localizedMonth = monthFormatter.format(date);

  // Capitalize the first letter of the month
  localizedMonth = localizedMonth.charAt(0).toUpperCase() + localizedMonth.slice(1);

  // Get the day and year as usual
  const day = date.getDate();
  const year = date.getFullYear();

  // Construct the desired format: Month, Day Year
  return `${localizedMonth}, ${day} ${year}`;
}
import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import HEADER from "../../base-components/Header/header_login";
import { Translate } from "react-auto-translate";
import { useGoogleLogin } from "@react-oauth/google";
import { FaVk, FaYandex } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import { YandexLogin, YandexLogout } from "react-yandex-login";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const clientID = "19561e1f8ae84b5986ce801da58889e2";

function Login() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const [userData, setUserData] = useState(undefined);
  const lang = localStorage.getItem("i18nextLng");

  const loginSuccess = (userData) => {
    console.log("User Data: ", userData);
    setUserData(userData);
  };

  const logoutSuccess = () => {
    setUserData(null);
  };

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      navigate("/dashboard");
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [isLoggedIn]);

  const do_login = async () => {
    if (email == "") {
      toast.error(NotificationsToasts?.enter_email?.notification);
      return;
    }
    if (!validateEmail(email)) {
      toast.error(NotificationsToasts?.enter_valid_email?.notification);
      return false;
    }
    if (password == "") {
      toast.error(NotificationsToasts?.enter_password?.notification);
      return;
    }

    const payload = {
      email: email,
      password: password,
      lang: lang,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_login");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };
  useEffect(() => {}, []);

  const do_google_login = async (name, emailg, pic, id) => {
    const payload = {
      email: emailg,
      password: id,
      fullname: name,
      pic: pic,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_signup_google");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        // window.location.reload();
        // navigate("/dashboard")
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const google_login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenResponse?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          do_google_login(data?.name, data?.email, data?.picture, data?.sub);
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const do_redirect_yandex_login = () => {
    // window.location.href = 'https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=http://localhost:3000/login&force_confirm=yes';
    // window.location.href = 'https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=https://wkraft.dedevelopers.org/login&force_confirm=yes';
    window.location.href =
      "https://oauth.yandex.com/authorize?response_type=code&client_id=19561e1f8ae84b5986ce801da58889e2&redirect_uri=https://aiword.online/login&force_confirm=yes";
  };

  const do_redirect_VK_login = () => {
    window.location.href =
      "https://oauth.vk.com/authorize?client_id=51822500&display=page&redirect_uri=https://wkraft.dedevelopers.org/login&scope=friends&response_type=token&v=5.131&state=123456";
  };

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    if (currentUrl.includes("?code=")) {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code != "") {
        do_yande_check_login(code);
      }
      console.log("Code:", code);
    }
  }, []);

  const do_yande_check_login = async (code) => {
    const payload = {
      code: code,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_confirm_yandex");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error_description);
      }
    }
  };
  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("#access_token=")) {
      const token = new URLSearchParams(window.location.hash.substring(1)).get(
        "access_token"
      );
      if (token != null) {
        do_vk_check_login(token);
      }
    } else {
      console.log("No code");
    }
  }, []);

  const do_vk_check_login = async (token) => {
    const payload = {
      token: token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_confirm_vk");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error_description);
      }
    }
  };

  return (
    <div className="layout-container container-fluid">
      <HEADER />
      <div className="layout-row row">
        <div className="scrollable-body noSidebar col-md-12 col-12">
          <div className="container-wrappe">
            <div className="col-4 p-0 card my-5 mx-auto loginForm card">
              <div className="card-body">
                <div className="p-4 box">
                  <h2 className="m-0">
                    {t("common.welcome")}
                  </h2>
                  <p>
                    {t("common.loginOrRegisterInAiword")}
                  </p>
                  {/* <form className> */}
                  <div className="mb-3">
                    <input
                      placeholder={t("common.emailAddress")}
                      required
                      type="email"
                      id="formBasicEmail"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      placeholder={t("common.password")}
                      required
                      type="password"
                      id="formBasicPassword"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <p style={{ textAlign: "right" }}>
                    <Link to="/forgot/password">
                      {t("common.forgotPassword")}
                    </Link>
                  </p>
                  <div className="d-grid gap-2">
                    <button
                      disabled={loading}
                      type="Submit"
                      className="btn btn-primary"
                      onClick={() => do_login()}
                    >
                      {t("common.login")}
                    </button>
                  </div>
                  {/* </form> */}
                  <div className="relative">
                    <hr />
                    <div className="or_text">
                      {t("common.or")}
                    </div>
                  </div>
                  <div>
                    <button
                      style={{
                        height: "40px",
                        backgroundColor: "#ED1E23",
                        marginTop: "10px",
                        color: "white",
                        border: "none",
                      }}
                      disabled={loading}
                      type="button"
                      className="w-100 googleSignin btn"
                      onClick={() =>
                        loading ? "" : do_redirect_yandex_login()
                      }
                    >
                      {loading ? (
                        <div className="flex p-2">
                          <span>
                            {t("common.pleaseWait")}...
                          </span>
                        </div>
                      ) : (
                        <>
                          <FaYandex className="SinupColorBtn" />
                          {t("common.loginWithYandex")}
                        </>
                      )}
                    </button>

                    <button
                      style={{
                        height: "40px",
                        marginTop: "10px",
                        backgroundColor: "white",
                      }}
                      disabled={loading}
                      type="button"
                      className="w-100 googleSignin btn"
                      onClick={() => (loading ? "" : google_login())}
                    >
                      {loading ? (
                        <div className="flex p-2">
                          <span>
                            {t("common.pleaseWait")}...
                          </span>
                        </div>
                      ) : (
                        <>
                          <FcGoogle className="SinupColorBtn" />
                          {t("common.loginWithGoogle")}
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="p-4 box text-center">
                  {t("common.dontHaveAnAccount")}{" "}
                  <Link to="/signup">
                    {t("common.signUp")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <>
          <div className="outer_image">
            <div className="inner_image">
              <div className="box_inner_image">
                <h4>
                  {t("common.pleaseWait")}...
                </h4>
                <p style={{ marginBottom: "0px" }}>
                  {t("common.WeAreProcessingYourRequestToLogin")}...
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

function Jobs() {
  const [stripePromise, setStripePromise] = useState(null);
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [clientSecret, setClientSecret] = useState("");
  const { state } = useLocation();
  const { getLoggedObject } = useAuth();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setisDarkMode] = useState(false);
  const { t } = useTranslation();
  const appearance = {
    theme: "stripe",

    variables: {
      colorPrimary: "#0570de",
      colorBackground: isDarkMode ? "#30313d" : "#fff",
      colorText: isDarkMode ? "#fafafa" : "#30313d",
      colorDanger: "#df1b41",
      borderRadius: "4px",
      // See all possible variables below
    },
  };

  useEffect(() => {
    get_pricing_info();
  }, []);

  useEffect(() => {
    if (state != null && state.plan) {
      setStripePromise(loadStripe(state.plan.publishableKey));
      setClientSecret(state.plan.client_secret);
    } else {
      toast.error(NotificationsToasts?.server_error?.notification);
      navigate("/dashboard");
    }
  }, []);

  const get_pricing_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      v: 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_all_plans");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setPlans(data?.data);
        setFaqs(data?.faqs);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="inline-pricing inPage">
          <div className="header-txt sub-head text-center">
            <div className="">
              <h2 style={{ textAlign: "center" }}>
                {t("common.payment")}
              </h2>
              <p>
                {t("common.upgradeYourPlan")}
              </p>
            </div>
          </div>
          <div className="container">
            <div className="tab-content">
              <div
                role="tabpanel"
                id="controlled-tab-example-tabpane-monthly"
                aria-labelledby="controlled-tab-example-tab-monthly"
                className="fade tab-pane active show"
              >
                <div className="loaderInline" />

                <div className="row mb-5 card p-5">
                  {clientSecret && stripePromise && (
                    <Elements
                      stripe={stripePromise}
                      options={{ clientSecret, appearance }}
                    >
                      <CheckoutForm plan={state.plan} />
                    </Elements>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;

import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LOGO from './../../assets/images/logo.png';
import PROFILE_IMAGE from './../../assets/images/profile.png';
import { AiOutlineLogout } from "react-icons/ai";
import Header from './../../base-components/Header';
import Footer from './../../base-components/Footer';
import SideBar from './../../base-components/SideBar';
import PLUS from './../../assets/images/plus.svg';
import EDIT from './../../assets/images/edit.svg';
import RARROW from './../../assets/images/arrowsquareright.svg';
import NavBar from './../../base-components/NavBar';
import NavBarLOGIN from './../../base-components/NavBar/navbar_login';
import Blog from './blog-content'
import WebsiteSEO from './website_seo'
import Marketing from './Marketing'
import SocialMedia from './SocialMedia'
import Other from './Other'
import Emails from "./Emails";
import Personal from "./Personal";
import HR from "./HR";
import MarketingTemp from "./Marketing_templates"
import Careers from "./Careers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFaroutesLists } from "../../lib/Redux/slices/webData";
const componentMapping = {
  'blog-content': Blog,
  'website-seo': WebsiteSEO,
  'other': Other,
  'marketing': Marketing,
  'social-media': SocialMedia,
  'emails-templates': Emails,
  'personal-templates': Personal,
  'hr-templates': HR,
  'marketing-templates': MarketingTemp,
  'careers-templates': Careers,
  // 'sentence-expander': SentenceExpandar,
  // 'pros-cons': ProsCons,
  // 'paragraph-writer': ParagraphWriter,
  // 'paragraph-points': ParagraphPoints,
  // 'paraphrase' : Parapharase,

};

function Jobs() {
  const [selectedfolder, setSelectedFolder] = useState(0);
  const dispatch = useDispatch();

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf('/');
  const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : '';
  const DynamicComponent = componentMapping[slugFromUrl];
  const { getLoggedObject } = useAuth();
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language;
  const [loading, setLoading] = useState(false);
  const handle_content = async () => {
    // const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      slug: 1,
      lang: currentLanguage,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_popular_categories"
    );
    if (isError) {
      setLoading(false);
    } else {
      if (data.action == "success") {
        dispatch(setFaroutesLists(data?.favourite_list));
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };
  useEffect(() => {
    handle_content()
  }, [])
  return (
    <>
     <div className="layout-container container-fluid">
        <NavBarLOGIN />
        <div className="layout-row row">
          <SideBar />
          <div className="scrollable-body col-md-9 col-12">
            {DynamicComponent && 
              <DynamicComponent />
            }
          </div>
        </div>
      </div>
    </>
);
}

export default Jobs;

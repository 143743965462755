import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";
import { doPost } from "./../../utils/apiCalls";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SideBar() {
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const { Favroute = [] } = useSelector((state) => state.web);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownTemplete, setDropdownTemplete] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totaltemp, setTotalTemp] = useState(0);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation()
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const firstSlashValue = path.substring(0, lastSlashIndex + 1);
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    handle_content();
  }, [currentLanguage]);
  const handle_content = async () => {
    // const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      slug: 1,
      lang: currentLanguage,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_popular_categories"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setTotal(data?.total);
        setTotalTemp(data?.total_temp);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };
  return (
    <>
      <div className="fixed-sidebar col-md-3 col-12">
        <div className="side-bar-nav column">
          <div className="sidebar-content">
            <div className="sidebar-nav">
              <nav className="sidebar-nav">
                <ul>
                  <li>
                    <Link
                      aria-current="page"
                      className={`${
                        slugFromUrl == "dashboard" ? "active" : ""
                      } sidebar-link`}
                      to="/dashboard"
                    >
                      <svg
                        width={20}
                        viewBox="0 0 122.88 121.135"
                        enableBackground="new 0 0 122.88 121.135"
                      >
                        <g>
                          <path d="M74.401,65.787h41.427c1.943,0,3.707,0.791,4.982,2.068c1.276,1.275,2.069,3.039,2.069,4.982v41.246 c0,1.941-0.793,3.707-2.069,4.98c-1.275,1.277-3.039,2.07-4.982,2.07H74.401c-1.942,0-3.706-0.793-4.982-2.07 c-1.275-1.273-2.068-3.039-2.068-4.98V72.838c0-1.943,0.793-3.707,2.068-4.982C70.695,66.578,72.459,65.787,74.401,65.787 L74.401,65.787z M7.052,0h41.426c1.942,0,3.707,0.792,4.983,2.069s2.068,3.04,2.068,4.983v41.245c0,1.943-0.792,3.707-2.068,4.982 c-1.276,1.276-3.041,2.069-4.983,2.069H7.052c-1.934,0-3.692-0.793-4.969-2.069l-0.007-0.006l-0.007,0.006 C0.792,52.003,0,50.239,0,48.296V7.052c0-1.943,0.792-3.707,2.069-4.983C2.162,1.976,2.26,1.888,2.359,1.807 C3.607,0.685,5.255,0,7.052,0L7.052,0z M48.131,7.397H7.397V47.95h40.733V7.397L48.131,7.397z M74.401,0h41.427 c1.943,0,3.707,0.792,4.982,2.069c1.276,1.276,2.069,3.04,2.069,4.983v41.245c0,1.943-0.793,3.707-2.069,4.982 c-1.275,1.276-3.039,2.069-4.982,2.069H74.401c-1.942,0-3.706-0.793-4.982-2.069c-1.275-1.275-2.068-3.04-2.068-4.982V7.052 c0-1.943,0.793-3.707,2.068-4.983C70.695,0.792,72.459,0,74.401,0L74.401,0z M115.482,7.397H74.748V47.95h40.734V7.397 L115.482,7.397z M7.052,65.787h41.426c1.942,0,3.707,0.791,4.983,2.068c1.276,1.275,2.068,3.039,2.068,4.982v41.246 c0,1.941-0.792,3.707-2.068,4.98c-1.276,1.277-3.041,2.07-4.983,2.07H7.052c-1.934,0-3.692-0.793-4.969-2.07l-0.007-0.006 l-0.007,0.006C0.792,117.791,0,116.025,0,114.084V72.838c0-1.943,0.792-3.707,2.069-4.982c0.093-0.094,0.191-0.182,0.291-0.264 C3.607,66.471,5.255,65.787,7.052,65.787L7.052,65.787z M48.131,73.184H7.397v40.553h40.733V73.184L48.131,73.184z M115.482,73.184 H74.748v40.553h40.734V73.184L115.482,73.184z" />
                        </g>
                      </svg>
                      <span>{t("common.dashboard")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/tools"}
                      className={`${
                        dropdown ||
                        firstSlashValue == "/tools/" ||
                        slugFromUrl == "tools"
                          ? "active"
                          : ""
                      } sidebar-link parent-link`}
                      onClick={() => setDropdown(!dropdown)}
                    >
                      <svg width={20} viewBox="0 0 122.88 121.135">
                        <path d="M120.62,39.52,63.1,68.12a3.75,3.75,0,0,1-3.33,0L2.1,39.45a3.78,3.78,0,0,1-.18-6.67L59.59.48a3.78,3.78,0,0,1,3.73,0L121,32.78a3.77,3.77,0,0,1-.33,6.74Zm-9.77,40.93a3.76,3.76,0,0,1,3.6-6.61l6.41,3.38a3.77,3.77,0,0,1,1.58,5.09A3.82,3.82,0,0,1,120.7,84L63.1,112.6a3.75,3.75,0,0,1-3.33,0L2.1,83.93a3.77,3.77,0,0,1-1.71-5A3.69,3.69,0,0,1,2,77.22l6-3.14a3.76,3.76,0,0,1,4,6.35L61.44,105l49.41-24.57ZM111,57.69a3.76,3.76,0,0,1,4.36-6l5.49,2.89a3.76,3.76,0,0,1-.16,6.74L63.1,89.92a3.75,3.75,0,0,1-3.33,0L2.1,61.25a3.78,3.78,0,0,1-1.71-5A3.72,3.72,0,0,1,2,54.54L7.9,51.43A3.77,3.77,0,0,1,12,57.74l49.47,24.6L111,57.69ZM61.44,60.54,111,35.87,61.44,8.09,11.83,35.87,61.44,60.54Z" />
                      </svg>
                      <span className="linkTitle">
                        <span>
                          {t("common.tools")}
                        </span>{" "}
                        <span className="count">{total}</span>
                      </span>
                      <span className="arrow-icon down" />
                    </Link>
                    {(dropdown ||
                      firstSlashValue == "/tools/" ||
                      slugFromUrl == "tools") && (
                      <ul>
                        <li>
                          <Link
                            className="sidebar-link"
                            to="/tools/blog-content"
                          >
                            {t("common.blogContent")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="sidebar-link"
                            to="/tools/website-seo"
                          >
                            {t("common.websiteSEO")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="sidebar-link"
                            to="/tools/social-media"
                          >
                            {t("common.socialMedia")}
                          </Link>
                        </li>
                        <li>
                          <Link className="sidebar-link" to="/tools/marketing">
                            {t("common.marketing")}
                          </Link>
                        </li>
                        <li>
                          <Link className="sidebar-link" to="/tools/other">
                            {t("common.others")}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link
                      onClick={() => setDropdownTemplete(!dropdownTemplete)}
                      // className="sidebar-link parent-link "
                      className={`${
                        dropdownTemplete ||
                        firstSlashValue == "/templates/" ||
                        slugFromUrl == "templates"
                          ? "active"
                          : ""
                      } sidebar-link parent-link`}
                    >
                      <svg
                        width="40px"
                        x="0px"
                        y="0px"
                        viewBox="0 0 111.26 122.88"
                        enableBackground="new 0 0 111.26 122.88"
                      >
                        <g>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M93.37,19.34h10.43c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27v88.62 c0,2.06-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19H25.35c-2.05,0-3.92-0.84-5.27-2.19c-1.35-1.35-2.19-3.21-2.19-5.27 v-11.88H7.46c-2.05,0-3.92-0.84-5.27-2.19C0.84,100,0,98.13,0,96.08V7.46C0,5.4,0.84,3.54,2.19,2.19C3.54,0.84,5.4,0,7.46,0h78.45 c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27V19.34L93.37,19.34L93.37,19.34z M48.45,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.89,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C51.95,42.92,50.37,44.5,48.45,44.5L48.45,44.5L48.45,44.5z M32.42,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.9,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C35.92,42.92,34.34,44.5,32.42,44.5L32.42,44.5L32.42,44.5z M60.47,41.35 c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h6.94c1.72,0,3.11,1.39,3.11,3.11c0,1.72-1.4,3.11-3.11,3.11H60.47 L60.47,41.35z M24.59,61.06c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19c1.72,0,3.11,1.4,3.11,3.11 c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,61.06z M24.59,79.46c-1.72,0-3.11-1.4-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19 c1.72,0,3.11,1.4,3.11,3.11c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,79.46z M24.28,103.54v11.88c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V26.8c0-0.29-0.12-0.56-0.32-0.75 c-0.2-0.2-0.46-0.32-0.75-0.32H93.37v70.34c0,2.05-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19L24.28,103.54L24.28,103.54 L24.28,103.54z M85.91,6.39H7.46c-0.29,0-0.56,0.12-0.75,0.32c-0.2,0.2-0.32,0.46-0.32,0.75v88.62c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V7.46c0-0.29-0.12-0.56-0.32-0.75 C86.47,6.51,86.2,6.39,85.91,6.39L85.91,6.39L85.91,6.39z"
                          />
                        </g>
                      </svg>
                      <span className="linkTitle">
                        <span>
                          {t("common.templates")}
                        </span>
                        <span className="count">{totaltemp}</span>
                      </span>
                      <span className="arrow-icon down" />
                    </Link>
                    <ul
                      style={{
                        display: `${
                          dropdownTemplete ||
                          firstSlashValue == "/templates/" ||
                          slugFromUrl == "templates"
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      <li>
                        <Link
                          className="sidebar-link"
                          to="/templates/emails-templates"
                        >
                          {t("common.emails")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="/templates/personal-templates"
                        >
                          {t("common.personal")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="/templates/hr-templates"
                        >
                          {t("common.manager")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="/templates/marketing-templates"
                        >
                          {t("common.marketing")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="/templates/careers-templates"
                        >
                          {t("common.careers")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      aria-current="page"
                      className={`${
                        slugFromUrl == "chat" ? "active" : ""
                      } sidebar-link`}
                      to="/chat"
                    >
                      <svg
                        fill="#000000"
                        width={20}
                        version="1.1"
                        id="Capa_1"
                        viewBox="0 0 60 60"
                      >
                        <g>
                          <path
                            d="M44.348,12.793H2.652C1.189,12.793,0,13.982,0,15.445v43.762l9.414-9.414h34.934c1.463,0,2.652-1.19,2.652-2.653V15.445
		C47,13.982,45.811,12.793,44.348,12.793z M10,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S12.206,35.777,10,35.777z
		 M23,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S25.206,35.777,23,35.777z M36,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4
		s4,1.794,4,4S38.206,35.777,36,35.777z"
                          />
                          <path
                            d="M57.348,0.793H12.652C11.189,0.793,10,1.982,10,3.445v7.348h34.348c2.565,0,4.652,2.087,4.652,4.652v25.332h11V3.445
		C60,1.982,58.811,0.793,57.348,0.793z"
                          />
                        </g>
                      </svg>
                      {t("common.AIChat")}
                    </Link>
                  </li>
                  <li>
                    <Link className="sidebar-link" to="/my-content">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="16px"
                        height="16px"
                        viewBox="0 0 512.001 512.001"
                        enableBackground="new 0 0 512.001 512.001;"
                        fill="#6a3dfd"
                      >
                        <g>
                          <g>
                            <g>
                              <path
                                d="M492.971,211.64h-50.443v-63.41c0-10.502-8.514-19.016-19.016-19.016H189.695V63.91c0-10.502-8.514-19.016-19.016-19.016
                      H19.016C8.514,44.894,0,53.408,0,63.91c0,13.692,0.001,370.375,0.001,384.179c0,10.432,8.48,19.017,19.029,19.017
                      c0.01,0,0.02-0.001,0.03-0.001h404.453c8.273,0,15.597-5.349,18.115-13.23l69.459-217.435
                      C515.005,224.175,505.831,211.64,492.971,211.64z M38.032,82.926h113.631v46.288H38.032V82.926z M38.032,167.247
                      c8.343,0,359.629-0.001,366.464-0.001v44.394H88.489c-8.272,0-15.596,5.349-18.115,13.229L38.032,326.092V167.247z
                      M409.625,429.073H45.057l57.321-179.403c11.05,0,346.991,0,364.557,0L409.625,429.073z"
                              />
                              <path
                                d="M206.005,358.389h30.973v30.973c0,10.502,8.514,19.016,19.016,19.016c10.502,0,19.016-8.514,19.016-19.016v-30.973
                      h30.973c10.502,0,19.016-8.514,19.016-19.016c0-10.503-8.514-19.016-19.016-19.016H275.01v-30.973
                      c0-10.502-8.514-19.016-19.016-19.016c-10.502,0-19.016,8.514-19.016,19.016v30.973h-30.973
                      c-10.502,0-19.016,8.514-19.016,19.016C186.989,349.875,195.503,358.389,206.005,358.389z"
                              />
                            </g>
                          </g>
                        </g>
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                      </svg>
                      {t("common.myContent")}
                    </Link>
                  </li>
                </ul>
              </nav>

              {/* /////// Favroutees ..... */}

              {Favroute.length > 0 && (
                <>
                  <div className="subHead">
                    {t("common.favorites")}
                  </div>

                  <ul>
                    {Favroute.map((fav, ind) => (
                      <li key={ind}>
                        <div
                          onClick={() =>
                            navigate("/" + fav.slug, { state: { v: fav } })
                          }
                          className="sidebar-link"
                        >
                          {fav?.image != "" && <img src={fav?.image} />}
                          {fav?.title}
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* -------------------- */}

              <div className="subHead">
              {t("common.getStarted")}
              </div>
              <nav>
                <ul>
                  {data.map((v, i) => (
                    <>
                      <li>
                        <div
                          onClick={() =>
                            navigate("/" + v.slug, { state: { v } })
                          }
                          className="sidebar-link"
                        >
                          {v.image != "" && <img src={v.image} />}
                          {v.title}
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SideBar;

import React, { useEffect, useState } from "react";
import NavBarLOGIN from "../../../base-components/NavBar/navbar_login";
import SideBar from "../../../base-components/SideBar";
import { useNavigate } from "react-router-dom";
import { Translate } from "react-auto-translate";
import { doPost } from "./../../../utils/apiCalls";
import useAuth from "./../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HelpCenter = () => {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleRoute = (slug) => {
    navigate(`/helpcenter/${slug}`);
  };

  const Get_Articles = async () => {
    const payload = {
      token: getLoggedObject().token,
      lang: "ru"
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/helpCenter");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.message == "success") {
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    Get_Articles();
  }, []);

  return (
    <div className="layout-container container-fluid">
      <NavBarLOGIN />
      <div className="layout-row row">
        <SideBar />

        <div className="scrollable-body col-md-9 col-12">
          <h2>
            {t("common.helpCenter")}
          </h2>
          <div>
            <div className="MainHelpCenter">
              {loading && <div className="loader" />}
              {Data &&
                Data.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleRoute(item?.slug)}
                      key={item?.id}
                      className="mb-4 parentArticle"
                    >
                      <div className="shadow HelpBox">
                        <div className="innerbox">
                          <div className="contentBox">
                            <h4>{currentLanguage === 'ru' ? item.title_in_russian : item.title }</h4>
                          </div>
                          <div className="Articles">
                            <p>{currentLanguage === 'ru' ? item.description_in_russian : item.description }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
